import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../responsive';
import { Link } from "react-router-dom";
import {theme_colors} from '../data';

import { withStyles, makeStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiTypography from '@material-ui/core/Typography';


const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    /* padding-top: 70px;
    padding-bottom: 70px; */
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    width: 90%;
    margin-top: 130px;
    margin-bottom: 50px;
    ${mobile({
        width: "100%",
    })}
`;
const Head = styled.div`
    width: 100%;
`;
const Title = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 45px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 48px;
    margin-bottom: 10px;
    ${tablet({
        fontSize: "42px",
        lineHeight: "45px"
    })}
    ${mobile({
        fontSize: "27px",
        lineHeight: "38px"
    })}
`;
const Desc = styled.div`
    width: 70%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        width: "100%",
        fontSize: "18px",
        lineHeight: "23px",
        fontWeight: "400",
        marginBottom: "60px"
    })}
`;
const Content = styled.div`
    width: 100%;
    margin-top: 50px;
`;
const Block = styled.div`
    width: 70%;
    margin-bottom: 20px;
    ${mobile({
        width: "100%"
    })}
`;

const Accordion = withStyles({
    root: {
      border: 'none',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: theme_colors.bg_main,
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      backgroundColor: theme_colors.bg_main,
    },
  }))(MuiAccordionDetails);

  const Typography = withStyles({
    root: {
      fontWeight: 300,
      fontSize: 16,
      fontFamily: 'Urbanist',
      color: '#323232',
    },
  })(MuiTypography);

  const useStyles = makeStyles({
    faqHead: {
        color: theme_colors.secondary_color,
        fontWeight: '600',
        fontSize: 18, 
        cursor: 'pointer',
        transition: 'all 0.5s ease',
    },
  });


export default function Faq() {

    const classes = useStyles();

    useEffect(() => {
        document.title = "Frequently asked questions | Kudi Group - Management Consulting and Financial Planners";
        window.scrollTo(0, 0)
    }, []);

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    };

  return (
        <Container>
            <Wrapper>
                    <Head>
                        <Title>Frequently asked questions</Title>
                        {/* <Desc>
                            
                        </Desc> */}
                    </Head>
                <Content>
                        <Block>
                            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography className={classes.faqHead}>1. What size companies do you work with?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Our clients range from 2 – 1,000 employees and from pre-launch start-ups to companies with $250 million dollars in annual revenue.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                <Typography className={classes.faqHead}>2. Why would my company need a consulting firm?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <p>Typically, our clients are experiencing one or more of the following scenarios:</p>
                                    <ul>
                                        <li> Struggling with challenges or obstacles within their business that is holding their company back from achieving growth and higher profitability</li>
                                        <li>Challenged by scaling their business or developing and implementing a growth plan that delivers intended results</li>
                                        <li>The company has outgrown their existing business processes, causing problems with daily operations (errors, increased costs, unreliable results, slower than expected output)</li>
                                    </ul>
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                <Typography className={classes.faqHead}>3. What are your consulting rates?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <p>We are a fixed price solution provider, who believes in providing ‘value’ to each of our clients and are committed to delivering results.</p>
                                    <p>We provide our clients with a not-to-exceed cost estimate before beginning any work. No one likes surprises, so we work with our clients to understand their specific business needs and then outline our plan of action and the associated costs to implement those solutions.</p>
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                <Typography className={classes.faqHead}>4. Do you write business plans?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    No, our firm has a team of small business strategists and business growth experts who can help entrepreneurs develop all the key elements needed for a strong business plan, however the actual writing of the business plan is not one of our provided services.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                <Typography className={classes.faqHead}>5. Do you work with start-ups?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Yes, some of our clients are start-ups. If you’re an entrepreneur developing or refining your business model, identifying target markets, defining your brand, mapping your business operations, strategizing your company launch, or seeking expertise in launching your company, then our firm can bring significant impact and expertise to your start-up.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                                <Typography className={classes.faqHead}>6. What can I expect from the initial consultation?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Every potential new client meets with the founder of our company or a managing partner within our firm. This initial consultation is not a sales call, we want to provide full transparency to who we are as a company and have an open dialogue with you to determine how we might help your business. We do not try to be all things to all people, if we don’t believe we can solve your company’s challenges or meet its goals & expectations, then we are going to openly tell you that. Our firm is built on strong values and the belief that ‘our success is achieved by helping you be successful.’
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                                <Typography className={classes.faqHead}>7. Why does my company need a business advisory and or business management?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <ul>
                                        <li>Motivation</li>
                                        <li>Accountability</li>
                                        <li>Business mentorship</li>
                                        <li>Brainstorming or idea generation</li>
                                        <li>Problem-resolution</li>
                                        <li>Sounding board for key decisions</li>
                                        <li>Strategizing or strategy development</li>
                                    </ul>
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                                <Typography className={classes.faqHead}>8. How can my company afford to hire a consultant if my company is already struggling?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    We believe the answer is, how can you afford not to hire someone to help you, as typically business challenges only get worse not better. More importantly, you need to partner with a consulting firm that can actually deliver ‘results’… not just any results but results that will positively impact your business and breathe new life into your company. That’s why our initial consultation with a potential new client is a no-cost, no-risk meeting where we can determine if we can provide a viable, cost-effective solution to you, and more importantly, you can learn more about our firm and whether we would be a good fit for you and your company.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                        <Block>
                            <Accordion square expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                                <Typography className={classes.faqHead}>9. What types of companies do you work with?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    <p>The list of industries and types of companies we have experience with is too vast to name them all, however here’s a small sample of the type of companies that are clients of our firm:</p>
                                    <ul>
                                        <li>Engineering services</li>
                                        <li>Custom boat trailers</li>
                                        <li>Construction</li>
                                        <li>Government services</li>
                                        <li>Specialty equipment manufacturing</li>
                                        <li>Medical training</li>
                                        <li>Cosmetics</li>
                                        <li>Technology firms (research & development)</li>
                                        <li>Metal fabrication</li>
                                        <li>Website & digital marketing</li>
                                        <li>Meal Prep Companies</li>
                                        <li>On Demand Transportation service</li>
                                    </ul>
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Block>
                </Content>
            </Wrapper>
        </Container>
  )
}
