import styled from 'styled-components';
import "../assets/css/contact.css";
import { mobile, tablet } from '../responsive';
import {theme_colors} from '../data';
import { useEffect } from 'react';
import { Category } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { 
    ArrowForwardOutlined,
    SentimentSatisfiedOutlined,
    CheckOutlined,
} from '@material-ui/icons';
import { Link } from "react-router-dom";

import Fade from 'react-reveal/Fade';
import Hero from '../components/main/Hero';
import HeroImg from '../assets/images/about-main.jpg';
import HeroImg2 from '../assets/images/home3.png';

import WhoWeAre from '../components/about/WhoWeAre';
import Team from '../components/about/Team';
import Stats from '../components/Stats';
import Industries from '../components/Industries';
import GetStarted from '../components/GetStarted';
import InstagramFeeds from '../components/InstagramFeeds';


const Container = styled.div`
    width: 100%;
    background-color: #f2f2f2;
`;
const HeroWrap = styled.div`
    width: 100%;
    background-color: ${theme_colors.bg_main};
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    ${tablet({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "120px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;


const useStyles = makeStyles({
    check: {
        color: theme_colors.secondary_color, 
        fontSize: 11,
    },
  });
export default function About() {
    const classes = useStyles();


    useEffect(() => {
        document.title = "About | Kudi Group - Management Consulting and Financial Planners";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <HeroWrap>
                <Hero 
                    pageImage={{
                        img1: HeroImg,
                        img2: HeroImg2
                    }}
                    title={{
                        text: "Our commitment is to listen to you",
                        fontSize: 30,
                    }}
                    desc={{
                        visible: true,
                        text: "Partnering with you to build strong client relationships and achieve measurable success in investments and growth.",
                        fontSize: 20
                    }}
                    cta={{
                        visible: false,
                        text: "let's chat"
                    }}

                />
            </HeroWrap>
            <WhoWeAre />           
            <Team />
            <Stats />
            <InstagramFeeds />
            <Industries />
            <GetStarted />
        </Container>
    );
}
