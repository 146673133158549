import styled from 'styled-components';
import "../assets/css/contact.css";
import { mobile, tablet } from '../responsive';
import {theme_colors} from '../data';
import { useEffect, useState } from 'react';
import { PhoneIphone, EmailOutlined, PinDrop } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { 
    ArrowForwardOutlined,
    SentimentSatisfiedOutlined,
    CheckOutlined,
} from '@material-ui/icons';
import { Link } from "react-router-dom";

import Fade from 'react-reveal/Fade';
import Hero from '../components/main/Hero';
import HeroImg from '../assets/images/contact-main.jpg';
import HeroImg2 from '../assets/images/home4.png';

import OurServices from '../components/OurServices';
import axios from 'axios';


const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme_colors.bg_main};
`;
const HeroWrap = styled.div`
    width: 100%;
    background-color: ${theme_colors.bg_main};
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    ${tablet({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "120px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper= styled.div`
    width: 80%;
    display: flex;
    padding: 70px 0px;
    ${tablet({
        width: "90%"
    })}
    ${mobile({
        flexDirection: "column",
        width: "100%"
    })}
`;
const Left = styled.div`
    width: 65%;
    ${mobile({
        width: "100%"
    })}
`;
const HeadText = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-left: 20px;
    margin-bottom: 50px;
    ${tablet({
        fontSize: "18px",
    })}
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        fontSize: "18px",
        paddingLeft: "20px",
        paddingRight: "20px"
    })}
`;
const FormWrap = styled.div`
    width: 100%;
`;
const MainForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap; 
`;
const FormItem = styled.div`
    width: calc(50% - 40px);
    margin: 0px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    ${mobile({
        width: "calc(100% - 40px)",
        margin: "0px 20px 40px 20px" 
    })}
`;
const Comment = styled.div`
    width: calc(100% - 40px);
    margin: 0px 20px 40px 20px;
`;
const Terms = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    ${mobile({
        marginLeft: "0px",
        paddingLeft: "20px",
        paddingRight: "20px"
    })}
`;
const THead = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 5px;
    ${tablet({
        fontSize: "18px",
    })}
    ${mobile({
        fontSize: "18px",
    })}
`;
const TDesc = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 10px;
    ${tablet({
        fontSize: "18px",
    })}
    ${mobile({
        fontSize: "18px",
        width: "100%"
    })}
`;
const CheckBoxWrap = styled.div`
    margin-bottom: 30px;
`;
const CBHolder = styled.div`
    
`;
const Right = styled.div`
    width: calc(35% - 70px);
    margin-left: 70px;
    ${mobile({
        width: "100%",
        marginLeft: "0px",
        marginTop: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
    })}
`;
const Title = styled.div`
    color: ${theme_colors.primary_color};
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
    ${tablet({
        fontSize: "18px",
    })}
    ${mobile({
        fontSize: "18px",
    })}
`;
const Contacts = styled.div`
    width: 100%;
`;
const Item = styled.div`
    margin-bottom: 30px;
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;
const FieldText = styled.div`
    margin-left: 10px;
    color: ${theme_colors.secondary_color};
    font-size: 22px;
    font-weight: 600;
    ${tablet({
        fontSize: "18px",
    })}
    ${mobile({
        fontSize: "18px",
    })}
`;
const Value = styled.div`
    color: ${theme_colors.primary_color};
    font-size: 18px;
    font-weight: 300;
`;
const FormSubmitMsg = styled.div`
    color: #009874;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 20px;
`;
const useStyles = makeStyles({
    contactIcons: {
        color: theme_colors.secondary_color, 
        fontSize: 28,
    },
  });
export default function Contact() {
    const classes = useStyles();

    useEffect(() => {
        document.title = "Contact Us | Kudi Group - Management Consulting and Financial Planners";
        window.scrollTo(0, 0)
    }, []);

    const [submitMsg, setSubmitMsg] = useState("");

    const [values, setValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        business_name: "",
        role: "",
        size: "0 to 5",
        revenue: "Pre Revenue",
        location: "United States",
        sector: "Automotive",
        message: "",
        conditions: false
    });
    const [focused, setFocused] = useState({
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        business_name: false,
        role: false,
        size: false,
        revenue: false,
        location: false,
        sector: false,
        message: false,
    });

  
    const handleFocus = (e) => {
      setFocused({ ...focused, [e.target.name]: true });
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        setSubmitMsg("");
        //console.log(values);
    };

    const resetFocus = () => {
        setFocused({
            firstname: false,
            lastname: false,
            email: false,
            phone: false,
            business_name: false,
            role: false,
            size: false,
            revenue: false,
            location: false,
            sector: false,
            message: false,
        });
        //let firstname = document.getElementsByName("firstname");

    };

    const clearFormInputs = () => {
        setValues({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            business_name: "",
            role: "",
            size: "0 to 5",
            revenue: "Pre Revenue",
            location: "United States",
            sector: "Automotive",
            message: "",
            conditions: false
        });
    };

    const handleCheckBox = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked });
    };

    const isInputValid = () => {
        let valid = true;
        for (let x in values) {
            if(values[x] == "" || false) {
                valid = false
            }
        }
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isInputValid()){
            //console.log('Valid');
            // send input to admin
            resetFocus();
            clearFormInputs();
            handlePost();
        }else {
            //console.log('not Valid');
        }    
    };

    const handlePost = async () => {
        try {
          axios.post('https://sheetdb.io/api/v1/tp0ta43d33zeh', {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone,
            business_name: values.business_name,
            role: values.role,
            size: values.size,
            revenue: values.revenue,
            location: values.location,
            sector: values.sector,
            message: JSON.stringify(values.message)
          })
          .then(function (response) {
            //console.log(response);
            setSubmitMsg("The form was submitted successfully");
          })
        } catch (err) {}
    
      };

    return (
        <Container>
            <HeroWrap>
                <Hero 
                    pageImage={{
                        img1: HeroImg,
                        img2: HeroImg2
                    }}
                    title={{
                        text: "Let's Connect",
                        fontSize: 30,
                    }}
                    desc={{
                        visible: true,
                        text: "We are committed to our promise of driving long term results through customer centric strategies that exceed expectations, and we would love the opportunity to learn more about your business and how we can help.",
                        fontSize: 20
                    }}
                    cta={{
                        visible: false,
                        text: "let's chat"
                    }}
                />
            </HeroWrap>
            <Wrapper>
                <Left>
                    <HeadText>
                        For a faster, more productive experience, please complete the form below. We look forward to hearing from you and understanding how to best support your business.
                    </HeadText>
                    <FormWrap>
                        <form className="form_" onSubmit={handleSubmit}>
                            <MainForm>
                                <FormItem>
                                    <label className="main_labels" for="firstname">First name:</label>
                                    <input 
                                        className="main_input" 
                                        type="text" 
                                        name="firstname"
                                        value={values.firstname}
                                        pattern= "^[A-Za-z]{3,}$"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.firstname.toString()}
                                        required 
                                    />
                                    <span className="form_error_msg">Enter 3 or more characters</span>
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="lastname">Last name:</label>
                                    <input 
                                        className="main_input" 
                                        type="text" 
                                        name="lastname"
                                        value={values.lastname}
                                        pattern= "^[A-Za-z]{3,}$"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.lastname.toString()}
                                        required 
                                    />
                                    <span className="form_error_msg">Enter 3 or more characters</span>
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="email">Email address:</label>
                                    <input 
                                        className="main_input" 
                                        type="email" 
                                        name="email"
                                        value={values.email}
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.email.toString()}
                                        required 
                                    />
                                    <span className="form_error_msg">Enter a valid email</span>
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="phone">Phone number:</label>
                                    <input 
                                        className="main_input" 
                                        type="text" 
                                        name="phone"
                                        value={values.phone}
                                        pattern= "^(\+\d{1,3}[- ]?)?\d{10,}$"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.phone.toString()}
                                        required 
                                    />
                                    <span className="form_error_msg">Enter 10 or more numbers</span>
                                </FormItem>                                
                                <FormItem>
                                    <label className="main_labels" for="business_name">Company/Business name:</label>
                                    <input 
                                        className="main_input" 
                                        type="text" 
                                        name="business_name"
                                        value={values.business_name}
                                        pattern= "^[A-Za-z ]{3,}$"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.business_name.toString()}
                                        required  
                                    />
                                    <span className="form_error_msg">Enter 3 or more characters</span>
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="role">Role:</label>
                                    <input 
                                        className="main_input" 
                                        type="text" 
                                        name="role"
                                        value={values.role}
                                        pattern= "^[A-Za-z ]{3,}$"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.role.toString()}
                                        required 
                                    />
                                    <span className="form_error_msg">Enter 3 or more characters</span>
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="size">Company size:</label>
                                    <select 
                                        className="select_inputs" 
                                        name="size"
                                        id="size"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.size.toString()}
                                        required 
                                    >
                                        <option value="0 to 5">0 - 5</option>
                                        <option value="5 to 10">5 - 10</option>
                                        <option value="10 to 20">10 - 20</option>
                                        <option value="20 and above">20 and above</option>
                                    </select>
                                    <span className="form_error_msg">Select an option</span>  
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="revenue">Annual revenue:</label>
                                    <select 
                                        className="select_inputs" 
                                        name="revenue"
                                        id="revenue"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.revenue.toString()}
                                        required 
                                    >
                                        <option value="Pre Revenue">Pre Revenue</option>
                                        <option value="$1000 - $200,000">$1000 - $200,000</option>
                                        <option value="$201,000 - $400,000">$201,000 - $400,000</option>
                                        <option value="$401,000 - $600,000">$401,000 - $600,000</option>
                                        <option value="$601,000 - $1M">$601,000 - $1M</option>
                                        <option value="$1M and above">$1M and above</option>
                                    </select>
                                    <span className="form_error_msg">error message</span>  
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="location">Location:</label>
                                    <select 
                                        className="select_inputs" 
                                        name="location"
                                        id="location"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.location.toString()}
                                        required 
                                    >
                                        <option value="United States">United States</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="Nigeria">Nigeria</option>
                                    </select>
                                    <span className="form_error_msg">error message</span>  
                                </FormItem>
                                <FormItem>
                                    <label className="main_labels" for="sector">Industry/Sector:</label>
                                    <select 
                                        className="select_inputs" 
                                        name="sector"
                                        id="sector"
                                        onBlur={handleFocus}
                                        onChange={onChange}
                                        focused={focused.sector.toString()}
                                        required 
                                    >
                                        <option value="Automotive">Automotive</option>
                                        <option value="Building and Construction">Building and Construction</option>
                                        <option value="Chemicals and Performance Technologies">Chemicals and Performance Technologies</option>
                                        <option value="Energy and Natural Resources">Energy and Natural Resources</option> 
                                        <option value="Financial Services">Financial Services</option>
                                        <option value="Food, Drink and Consumer Goods">Food, Drink and Consumer Goods</option> 
                                        <option value="Government and Public Sector">Government and Public Sector</option> 
                                        <option value="Healthcare">Healthcare</option> 
                                        <option value="Industrial Manufacturing">Industrial Manufacturing</option> 
                                        <option value="Infrastructure">Infrastructure</option> 
                                        <option value="Life Sciences">Life Sciences</option> 
                                        <option value="Media">Media</option> 
                                        <option value="Professional and Business Services">Professional and Business Services</option> 
                                        <option value="Real Estate">Real Estate</option> 
                                        <option value="Retail">Retail</option> 
                                        <option value="Technology">Technology</option> 
                                        <option value="Telecommunications">Telecommunications</option> 
                                        <option value="Transport and Logistics">Transport and Logistics</option> 
                                        <option value="Travel, Leisure, Tourism">Travel, Leisure, Tourism</option>
                                    </select>
                                    <span className="form_error_msg">error message</span>  
                                </FormItem>

                            </MainForm>
                            <Comment>
                                <label className="main_labels" for="message">Comment:</label>
                                <input 
                                    className="main_input" 
                                    type="text" 
                                    name="message"
                                    value={values.message}
                                    placeholder='Anything you may want us to know'
                                    onBlur={handleFocus}
                                    onChange={onChange}
                                    focused={focused.message.toString()}
                                    required    
                                />
                                <span className="form_error_msg">Enter 3 or more characters</span>
                            </Comment>
                            <Terms>
                                <THead>Terms and Conditions</THead>
                                <TDesc>
                                    To respond to your request, this form (including any personal information) may be shared with other Kudi Group member firms and transferred and/or stored across borders. A member of our team will be reviewing your request immediately and will respond within five business days.
                                </TDesc>
                                <CheckBoxWrap>
                                    <CBHolder>
                                        <input 
                                            type="checkbox" 
                                            name="conditions"
                                            onChange={handleCheckBox}
                                            required
                                        />
                                        <span className="terms">I acknowledge that I have read and understood the terms stated in the Kudi Group <Link to={`/terms`} style={{ textDecoration: 'none'}}>Terms and Conditions</Link></span>
                                    </CBHolder>
                                    <span className="form_error_msg">error message</span>
                                </CheckBoxWrap>
                                <button className="btn_submit">Submit</button>
                                <FormSubmitMsg>{submitMsg}</FormSubmitMsg>
                            </Terms>
                        </form>
                    </FormWrap>
                </Left>
                <Right>
                    <Title>Contact</Title>
                    <Contacts>
                        <Item>
                            <Top>
                                <PhoneIphone className={classes.contactIcons} />
                                <FieldText>Phone</FieldText>
                            </Top>
                            <a href="tel:+1 614 733 9671"><Value>614 733 9671</Value></a>
                        </Item>
                        <Item>
                            <Top>
                                <EmailOutlined className={classes.contactIcons} />
                                <FieldText>Email</FieldText>
                            </Top>
                            <a href="mailto:info@kudigroup.com"><Value>info@kudigroup.com</Value></a>
                        </Item>
                        <Item>
                            <Top>
                                <PinDrop className={classes.contactIcons} />
                                <FieldText>Address</FieldText>
                            </Top>
                            <Value>Columbus, OH</Value>
                        </Item>
                    </Contacts>
                </Right>
            </Wrapper>
        </Container>
    );
}
