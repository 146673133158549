import styled from 'styled-components';
import { mobile } from '../responsive';
import HomeHero from '../components/HomeHero';
import WhoWeAreSection from '../components/WhoWeAreSection';
import Partners from '../components/Partners';
import Testimonial from '../components/Testimonial';
import HappyClient from '../components/HappyClient';
import Stats from '../components/Stats';
import Industries from '../components/Industries';
import { useEffect } from 'react';

import Fade from 'react-reveal/Fade';

const Container = styled.div`
    width: 100%;
    background-color: #f2f2f2;
`;

export default function Home() {

    useEffect(() => {
        document.title = "Kudi Group - Management Consulting and Financial Planners";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Fade bottom>
                <HomeHero />
            </Fade>
            <WhoWeAreSection />
            <Fade bottom>
                <Partners />
            </Fade>
                <Testimonial />
                <HappyClient />
            <Fade bottom>
                <Stats />
            </Fade>
                <Industries />
        </Container>
    );
}
