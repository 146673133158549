import {theme_colors, homePageSections} from '../../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import HCImg from '../../assets/images/about2.png';
import TeamImg1 from '../../assets/images/team1.png';

import { ArrowRightAlt } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    ${tablet({
         width: '90%',
    })}
    ${mobile({
        width: '100%',
        flexDirection: "column"
    })}
`;

const Left = styled.div`
    width: 30%;
    ${mobile({
         width: '100%',
    })}
`;
const ImageHolder = styled.img`
    width: 100%;
    ${mobile({

    })}
`;
const Right = styled.div`
    width: calc(70% - 80px);
    margin-left: 80px;

    ${mobile({
         width: '100%',
         marginLeft: "0px",
         marginTop: "30px",
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
    })}
`;
const Title = styled.div`
    width: 100%;
    color: ${theme_colors.primary_color};
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    ${tablet({

    })}
    ${mobile({
        fontSize: "16px",
        textAlign: "center"
    })}
`;
const DescWrap = styled.div`
    margin-top: 5px;
    margin-bottom: 30px;
`;
const Desc = styled.div`
    width: 80%;
    color: ${theme_colors.secondary_color};
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        width: "100%",
        textAlign: "center",
        fontSize: "25px",
        lineHeight: "29px",
        fontWeight: "600",
        marginBottom: "30px"
    })}
`;
const Subs = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    ${mobile({
         flexDirection: "column",
         alignItems: "center"
    })}
`;
const DescSub = styled.div`
    width: calc(100%);
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    margin: 0px 20px 20px 0px;
    ${tablet({
        fontSize: "16px",
        lineHeight: "19px"
    })}
    ${mobile({
        width: "100%",
        textAlign: "center",
        fontSize: "18px",
        lineHeight: "22px",
        fontWeight: "300",
        margin: "0px 0px 20px 0px"
    })}
`;
const BtnAlt = styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    margin-left: 10px;
    ${mobile({
        marginLeft: "0px"
    })}
`;
const BtnText = styled.span`
    color: ${theme_colors.primary_color};
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    text-transform: uppercase;
    width: max-content;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        letter-spacing: 1px;
    }
    ${tablet({

    })}
    ${mobile({
        textAlign: "center"
    })}
`;

const useStyles = makeStyles({
    btnAltIcon: {
        color: theme_colors.primary_color, 
        fontSize: 22, 
        cursor: 'pointer',
        transition: 'all 0.5s ease',
        '&:hover': {
         },
        '@media (min-width: 780px)' : {
            fontSize: 32
        }
    },
  });
export default function WhoWeAre() {
    const classes = useStyles();

    return (
        <Container>
            <Wrapper>
            <Left>
                <ImageHolder src={HCImg}/>
            </Left>
            <Right>
                {/* <Title>
                    Who we are
                </Title> */}
                <DescWrap>
                    <Desc>
                        About Kudi Group
                    </Desc>
                    <Subs>
                        <DescSub>
                            We are a consulting company focused on supporting early stage and small cap companies, driving long-term value through customer-centric strategies, deep business insights, and result-driven management. 
                        </DescSub>
                        <DescSub>
                            With over 30 years of diverse industry experience, our seasoned professionals bring a wealth of knowledge and expertise to offer comprehensive and customized solutions that meet businesses' unique needs and challenges. 
                        </DescSub>
                        <DescSub>
                            Our hands-on, collaborative approach allows us to understand our clients' needs, bring a fresh perspective and innovative solutions to the table, and deliver exceptional service. Whether you seek short-term support or a long-term partnership, we are here to help.
                        </DescSub>
                    </Subs>
                </DescWrap>
                <Link to={`/contact`} style={{ textDecoration: 'none' }}>
                    <BtnAlt>
                        <BtnText>Let's talk</BtnText>
                        <ArrowRightAlt className={classes.btnAltIcon} />
                    </BtnAlt>
                </Link>
            </Right>

            </Wrapper>
        </Container>
    );
}
