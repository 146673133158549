import {theme_colors, homePageSections} from '../../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import ClientImg1 from '../../assets/images/team/kunle.jpg';
import ClientImg2 from '../../assets/images/team/akin.jpg';
import ClientImg3 from '../../assets/images/team/tosin.jpg';
import ClientImg4 from '../../assets/images/team/chibuzor.jpg';
import ClientImg5 from '../../assets/images/team/ken.jpg';
import ClientImg6 from '../../assets/images/team/miriam.jpg';




const Container = styled.div`
    width: 100%;
    height: max-content;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    width: 80%;
    flex-direction: column;
    ${tablet({
        width: '90%',
        flexDirection: "column"
    })}
    ${mobile({
        width: '100%'
    })}
`;
const TWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    ${tablet({
        width: '100%',
        marginTop: "30px",
        marginLeft: "0px"
    })}
`;
const Testimony = styled.div`
    width: calc(50% - 40px);
    margin: 0px 20px 20px 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    ${tablet({
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: " 40px 20px",
        margin: " 0px 0px 20px 0px"
    })}
`;
const TLeft = styled.div`
    width: calc(100% - 130px);
    display: flex;
    flex-direction: column;
    ${tablet({
        width: '100%',
        alignItems: "center"
    })}
`;
const TImage = styled.img`
    width: 100%;
    border-radius: 50%;
`;
const TRight = styled.div`
    width: 100px;
    margin-right: 30px;
    ${tablet({
        width: "200px",
        marginRight: "0px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "column",
        alignItems: "center"
    })}
`;
const Name = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    ${tablet({
        textAlign: "center",
        fontSize: "18px"
    })}
`;
const Company = styled.div`
    color: ${theme_colors.primary_color};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    ${tablet({
        textAlign: "center"
    })}
`;
const Bio = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 14px;
    font-weight: 300;
    ${tablet({
        textAlign: "center",
        fontSize: "18px"
    })}
`;
const HeadText = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 25px;
    font-weight: 900;
    text-align: center;
    text-transform: capitalize;
    ${tablet({
        fontSize: "24px",
    })}
`;

export default function Team() {

    return (
        <Container>
            <Wrapper>
                <HeadText>Our team</HeadText>
                <TWrap>
                    <Testimony>
                        <TRight>
                            <TImage src={ClientImg1}/>
                        </TRight>
                        <TLeft>
                            <Name>Kunle Falae</Name>
                            <Company>President & CEO</Company>
                            <Bio>
                                A finance and business leader with diverse industry experience, Kunle shares a distinct perspective to business transformation support and has transformed strategic ideas into compelling outcomes at scale. He holds a BS in Business Administration (Accounting) from The Ohio State University, and certificates from the University of Pennsylvania (Wharton) and Yale University.
                            </Bio>
                        </TLeft>
                    </Testimony>
                    <Testimony>
                        <TRight>
                            <TImage src={ClientImg2}/>
                        </TRight>
                        <TLeft>
                            <Name>Akin Ayemobola, CPA, MBA</Name>
                            <Company>Board Member & Sr. Strategic Advisor</Company>
                            <Bio>
                                Akin is a finance executive with close to two decades of diverse industry experience (Health Care and Banking) in developing strategies, managing initiatives and delivering results. He is a recipient of the Worldwide Financial Leadership Award from a Fortune 15 company and has a unique view of managing teams and driving business growth at scale.
                            </Bio>
                        </TLeft>
                    </Testimony>
                    <Testimony>
                        <TRight>
                            <TImage src={ClientImg6}/>
                        </TRight>
                        <TLeft>
                            <Name>Miriam Deleawe, Esq.</Name>
                            <Company>General Counsel & Legal Services</Company>
                            <Bio>
                                Miriam is a proactive business leader and attorney with nearly a decade of experience providing exceptional legal counsel and strategic solutions to diverse businesses, including Fortune 500 companies. Her expertise includes, but is not limited to, litigation and enforcement, trademark procurement, legal document preparation, analytics, and research. Miriam holds a Juris Doctor (JD) degree from the University of Wisconsin Law School, as well as certifications in Project Management and IT Service Management (CSM, SAFe5 and ITIL4).
                            </Bio>
                        </TLeft>
                    </Testimony>
                    <Testimony>
                        <TRight>
                            <TImage src={ClientImg3}/>
                        </TRight>
                        <TLeft>
                            <Name>Tosin Jemilusi</Name>
                            <Company>Scrum Master - Agile Coach</Company>
                            <Bio>
                            Tosin is a Scrum Master with years of experience driving improvements to organizational processes and complex product deliveries through the application of Scrum and Kanban frameworks. He emphasizes empirical decision making, team mentorship, and facilitation skills to achieve client satisfaction. He is certified in PSM I, CSM, and SAFe 5 Scrum Master with a proven track record of reducing cycle time, increasing team velocity and saving costs.
                            </Bio>
                        </TLeft>
                    </Testimony>
                    <Testimony>
                        <TRight>
                            <TImage src={ClientImg5}/>
                        </TRight>
                        <TLeft>
                            <Name>Ken Oganwu, Esq., LL.M</Name>
                            <Company>General Counsel & Legal Services</Company>
                            <Bio>
                                Ken is a licensed attorney with close to a decade of experience in advising clients on various 
                                areas of law practice.
                                Ken also holds a Master of Laws degree in Corporate Law and Securities from The Ohio State University.
                            </Bio>
                        </TLeft>
                    </Testimony>
                    <Testimony>
                        <TRight>
                            <TImage src={ClientImg4}/>
                        </TRight>
                        <TLeft>
                            <Name>Chibuzor Nnorom</Name>
                            <Company>Information Technology Manager</Company>
                            <Bio>
                            Chibuzor is a seasoned IT leader with a passion for innovation and a commitment to success. He has a track record of successfully leading the development and implementation of numerous IT projects, resulting in improved productivity and efficiency. Chibuzor brings a unique perspective to driving business transformation and delivering outcomes through technology.
                            </Bio>
                        </TLeft>
                    </Testimony>
                </TWrap>
            </Wrapper>

        </Container>
    );
}
