import styled from 'styled-components';
import "../assets/css/contact.css";
import { mobile, tablet } from '../responsive';
import {theme_colors} from '../data';
import { useEffect } from 'react';
import { Category } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { 
    ArrowForwardOutlined,
    SentimentSatisfiedOutlined,
    CheckOutlined,
} from '@material-ui/icons';
import { Link } from "react-router-dom";

import Fade from 'react-reveal/Fade';
import Hero from '../components/main/Hero';
import HeroImg from '../assets/images/services.jpg';
import HeroImg2 from '../assets/images/home2.png';

import OurServices from '../components/OurServices';

import Stats from '../components/Stats';
import Testimonial from '../components/Testimonial';


const Container = styled.div`
    width: 100%;
    background-color: #f2f2f2;
`;
const HeroWrap = styled.div`
    width: 100%;
    background-color: ${theme_colors.bg_main};
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    ${tablet({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "120px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;


const useStyles = makeStyles({
    check: {
        color: theme_colors.secondary_color, 
        fontSize: 11,
    },
  });
export default function Services() {
    const classes = useStyles();


    useEffect(() => {
        document.title = "Our Services | Kudi Group - Management Consulting and Financial Planners";
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container>
            <Fade bottom>
                <HeroWrap>
                    <Hero 
                        pageImage={{
                            img1: HeroImg,
                            img2: HeroImg2
                        }}
                        title={{
                            text: "Unlock Your Business Potential with Our Services",
                            fontSize: 30,
                        }}
                        desc={{
                            visible: true,
                            text: "Discover innovative solutions for growth and expert strategies tailored to meet your unique business needs.",
                            fontSize: 20
                        }}
                        cta={{
                            visible: false,
                            text: "let's chat"
                        }}

                    />
                </HeroWrap>
            </Fade>
            <OurServices />
            <Fade bottom>
                <Stats />
                <Testimonial />
            </Fade>
        </Container>
    );
}
