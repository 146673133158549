import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import MainLogoImg from '../assets/instagram_media/logo-icon.png';
import axios from 'axios';
import HCImg from '../assets/images/happy_client.png';
import { feeds } from '../data';
import { Instagram } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 60px 30px;
    box-sizing: border-box;
    ${tablet({
        width: '90%',
    })}
    ${mobile({
        width: '100%',
        flexDirection: "column",
        padding: "50px 10px 30px 10px"
    })}
`;

const Head = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 22px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 10px;
    line-height: 25px;
`;
const SubHead = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;
const HandleIcon = styled.img`
    width: 30px;
    margin-right: 10px;
`;
const SubHeadText = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 25px;
`;
const FeedWrap = styled.div`
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
`;
const Feed = styled.div`
    width: calc(100%/3);
    //aspect-ratio : 1 / 1;
    height: 350px;
    position: relative;
    ${tablet({

    })}
    ${mobile({
       width: "100%"
    })}
`;
const FeedImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const IconWrap = styled.div`
    width: 40px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
`;
const IgMedia = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    background-color:  ${theme_colors.primary_lite_color};
    &:hover {
        opacity:0;
        cursor: pointer
    }
`;
const MediaInfo = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
    background-color: ${theme_colors.primary_lite_color};
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
`;
const InfoText = styled.div`
`;
const SvBtn = styled.div`
    width: max-content;
    padding: 10px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme_colors.primary_color};
    margin-top: 40px;
    transition: all 0.3s ease;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background-color: ${theme_colors.secondary_color};
    }
    ${tablet({
        width: "90%"
    })}
    ${mobile({
        width: "100%",
        height: '60px',
        padding: "10px 40px"
    })}
`;
const SvBtnText = styled.div`
    color: ${theme_colors.bg_main};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: capitalize;
`;

const useStyles = makeStyles({
    FeedIgIcons: {
        color: "#ffffff", 
        fontSize: 25
    },
  });
export default function InstagramFeeds() {
    const classes = useStyles();
    // const InstagramUserId = "5871503539600991";
    // const InstagramToken = "IGQVJYNWRxUHdRWDBkZAHFwdG8tTlowQ245ZAm1Ibkh5cmZAWcVNJbWw4U2E3WUxFN2NEeUNObnM0d0lZAcFlrR0hyV2JNcVFwc0d3ZA3JTdDNDSWxyWmNYVTdqS0F6QjU5VlpkWHdmNUxleXFhQmNzeVU3QQZDZD";
    const InstagramUserId = "6519506528082493";
    const InstagramToken = "https://graph.instagram.com/6519506528082493?fields=media&access_token=IGQVJXSGxWOEplajhzNTNxU2kzTXdUaVFNNkNaOXRwV21hSlpHNkxfTVM3Ny1wZAW80QjhDU3l3bUlMYXJ5Y05uZAzZAabVZAXaF9ZARGFwNkNmLVp4T1hOcTlpdzZAwVXpGLTIyRGZAVQUxlWnFnUkFmVUFqYwZDZD";
    const [IgFeeds, setIgFeeds] = useState([]);
    const [media, setMedia] = useState([]);


    useEffect(() => {
        getInstagramMediaIDs();
     }, []);

    //  useEffect(() => {
    //     setIgFeeds((prev) => 
    //         [...prev].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    //     );
    // }, [sort]);


     const getInstagramMediaIDs = async () => {
        let url = `https://graph.instagram.com/${InstagramUserId}?fields=media&access_token=${InstagramToken}`;
        try {
                axios.get(url).then(res => {
                    getInstagramMedia(res.data);
                    //console.log(res.data);
                });
        } catch {}
    };

     const getInstagramMedia = async (mediaIDs) => {
        let InstagramMedia = [];
        const mediaSize = mediaIDs.media.data.length;


        if( mediaSize >= 9) {
            for(var i = 0; i < 9; i++) {
                let url = `https://graph.instagram.com/${mediaIDs.media.data[i].id}?fields=id,media_type,media_url,caption,username,permalink,timestamp&access_token=${InstagramToken}`;
                try {
                        axios.get(url).then(res => {
                            InstagramMedia.push(res.data);
                            //console.log(" Log Media: "+ res.data);
                            if (InstagramMedia.length == 9 ) {
                                setIgFeeds(InstagramMedia.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)));
                                //console.log(" Log Media: "+ InstagramMedia);
                            }
                        });
                    } catch {}
            }

        }else {
            for(var i = 0; i < mediaSize; i++) {
                let url = `https://graph.instagram.com/${mediaIDs.media.data[i].id}?fields=id,media_type,media_url,caption,username,permalink,timestamp&access_token=${InstagramToken}`;
                try {
                        axios.get(url).then(res => {
                            InstagramMedia.push(res.data);
                            if (InstagramMedia.length == mediaSize) {
                                setIgFeeds(InstagramMedia.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)));
                                //console.log(InstagramMedia);
                            }
                        });
                    } catch {}
            }
        }
    };
    

    return (
        <Container>
            <Wrapper>
                <Head>Check us out on Instagram</Head>
                <SubHead>
                    <HandleIcon src={MainLogoImg} />
                    <SubHeadText>kudigroup</SubHeadText>
                </SubHead>
                {/* <>
                {IgFeeds.length}
                </> */}
                <FeedWrap>
                    {
                        IgFeeds?.map(feed => {
                            if(feed.media_type != "VIDEO") {
                                return  <Feed key={feed.id}>
                                            <MediaInfo>
                                                <InfoText>
                                                    {feed.caption}
                                                </InfoText>
                                            </MediaInfo>
                                            <IgMedia>
                                                <a href={feed.permalink} target="_blank">
                                                    <FeedImg src={feed.media_url}/>
                                                    <IconWrap>
                                                        <Instagram className={classes.FeedIgIcons} />
                                                    </IconWrap>
                                                </a>
                                            </IgMedia>
                                        </Feed>
                            }
                            if(feed.media_type == "VIDEO") {
                                return  <Feed key={feed.id}>
                                            <MediaInfo>
                                                <InfoText>
                                                    {feed.caption}
                                                </InfoText>
                                            </MediaInfo>
                                            <IgMedia>
                                                <a href={feed.permalink} target="_blank">
                                                    <video autoPlay muted loop className="IgVideo">
                                                        <source src={feed.media_url} type="video/mp4" />
                                                    </video>
                                                    <IconWrap>
                                                        <Instagram className={classes.FeedIgIcons} />
                                                    </IconWrap>
                                                </a>    
                                            </IgMedia>
                                        </Feed>
                            }
                        })

                    }
                </FeedWrap>
                <SvBtn>
                    <a href='https://www.instagram.com/kudigroup/' target="_blank">
                        <SvBtnText>Find us on Instagram</SvBtnText>
                    </a>
                </SvBtn>
            </Wrapper>
        </Container>
    );
}
