import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import LogoImg1 from '../assets/images/partner-logos/client_logo1.png';
import LogoImg2 from '../assets/images/partner-logos/client_logo2.png';
import LogoImg3 from '../assets/images/partner-logos/client_logo3.png';
import LogoImg4 from '../assets/images/partner-logos/client_logo4.png';
import LogoImg5 from '../assets/images/partner-logos/client_logo5.png';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 70%;
    flex-wrap: wrap;
    ${tablet({
        width: '90%'
    })}
    ${mobile({
        width: '100%'
    })}
`;
const LogoWrap = styled.div`
    width: calc(20% - 40px);
    margin: 0px 20px 30px 20px;
    ${mobile({
        width: 'calc(50% - 40px)',
    })}
`;
const Logo = styled.img`
    width: 100%;
`;



export default function Partners() {

    return (
        <Container>
            <Wrapper>
                <LogoWrap>
                    <Logo src={LogoImg1}/>
                </LogoWrap>
                <LogoWrap>
                    <Logo src={LogoImg2}/>
                </LogoWrap>
                <LogoWrap>
                    <Logo src={LogoImg3}/>
                </LogoWrap>
                <LogoWrap>
                    <Logo src={LogoImg4}/>
                </LogoWrap>
                <LogoWrap>
                    <Logo src={LogoImg5}/>
                </LogoWrap>
            </Wrapper>

        </Container>
    );
}
