import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import ClientImg1 from '../assets/images/testimonials/florence.jpg';
import ClientImg2 from '../assets/images/testimonials/obi.jpg';
import ClientImg3 from '../assets/images/testimonials/temi.jpg';
import HelpImg from '../assets/images/help.png';



const Container = styled.div`
    width: 100%;
    height: max-content;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    width: 80%;
    ${tablet({
        width: '90%',
        flexDirection: "column"
    })}
    ${mobile({
        width: '100%',
        flexDirection: "column"
    })}
`;
const Left = styled.div`
    width: 40%;
    /* position: relative; */
    ${tablet({
        width: '100%',
    })}
`;
const GradientImg = styled.img`
    width: 100%;
    ${mobile({

    })}
`;
const GradientBg = styled.div`
    width: 100px;
    height: 100px;
    left: 0px;
    top: 70px;
    position:  absolute;
    background-image: linear-gradient(135deg, #009874, #56be89);
    ${mobile({
        top: "30px"
    })}
`;
const TextHolder = styled.div`
    width: 85%;
    height: max-content;
    left: 30px;
    top: -40px;
    background-color: ${theme_colors.primary_lite_color};
    position:  absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    ${mobile({
        width: '85%',
    })}
`;
const Text = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
    ${mobile({
        fontSize: '18px',
        lineHeight: "22px"
    })}
`;
const Right = styled.div`
    width: calc(60% - 40px);
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    ${tablet({
        width: '100%',
        marginTop: "30px",
        marginLeft: "0px"
    })}
`;
const Testimony = styled.div`
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    ${tablet({
        flexDirection: "column"
    })}
`;
const TLeft = styled.div`
    width: calc(100% - 130px);
    display: flex;
    flex-direction: column;
    ${tablet({
        width: '100%',
    })}
`;
const Bio = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
`;
const Bottom = styled.div`
    width: 100%;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    ${tablet({
        flexDirection: "column",
        marginBottom: "20px"
    })}
`;
const Name = styled.div`
    margin-right: 15px;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 600;
`;
const Company = styled.div`
    color: ${theme_colors.primary_color};
    font-size: 16px;
    font-weight: 600;
`;
const TRight = styled.div`
    width: 100px;
    margin-left: 30px;
    ${tablet({
        marginLeft: "0px"
    })}
`;
const TImage = styled.img`
    width: 100%;
    border-radius: 50%;

`;
export default function Testimonial() {

    return (
        <Container>
            <Wrapper>
                <Left>
                    <Fade bottom>
                    <GradientImg src={HelpImg} />
                    </Fade>
                    {/* <GradientBg />
                    <TextHolder>
                        <Text>
                            For over 30years, we've built our reputation by
                            helping others build theirs.
                        </Text>
                    </TextHolder> */}
                </Left>
                <Right>
                    <Fade bottom>
                    <Testimony>
                        <TLeft>
                            <Bio>
                                Kudi Group serves as our strategic advisors to support our vision of integrated wellness through hydration. Their work across our business continues to position us for scale in the high growth space of Intravenous (IV) therapy 
                            </Bio>
                            <Bottom>
                                <Name>Florence Ayodele, BSN, RN</Name>
                                <Company>Founding Partner - Re-Hydrate Wellness</Company>
                            </Bottom>
                        </TLeft>
                        <TRight>
                            <TImage src={ClientImg1}/>
                        </TRight>
                    </Testimony>
                    <Testimony>
                        <TLeft>
                            <Bio>
                                Kudi Group has afforded us the opportunity to do what we do best at Xpress Elite Chauffeurs with their bookkeeping and business management suite. It has allowed our chauffeurs to focus on delivering unparalleled client experience in the on-demand luxury transportation space
                            </Bio>
                            <Bottom>
                                <Name>Obi Nnwokike</Name>
                                <Company>VP, Operations - Xpress Elite Chauffeurs</Company>
                            </Bottom>
                        </TLeft>
                        <TRight>
                            <TImage src={ClientImg2}/>
                        </TRight>
                    </Testimony>
                    <Testimony>
                        <TLeft>
                            <Bio>
                                We saw a significant growth in our meal prep business during the pandemic, the team at Kudi Group was able to build an analytic infrastructure for us to understand our profitability better than we’ve ever understood them in our company history
                            </Bio>
                            <Bottom>
                                <Name>Temi Jemilusi</Name>
                                <Company>President and CEO - JEMI Foods</Company>
                            </Bottom>
                        </TLeft>
                        <TRight>
                            <TImage src={ClientImg3}/>
                        </TRight>
                    </Testimony>
                    </Fade>
                </Right>
            </Wrapper>

        </Container>
    );
}
