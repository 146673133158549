import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import HeroImg from '../assets/images/15019.jpg';
import HeroImg2 from '../assets/images/home1.png';
import Hero from '../components/main/Hero';

import Fade from 'react-reveal/Fade';


const Container = styled.div`
    width: 100%;
`;
const HeroWrap = styled.div`
    width: 100%;
    background-color: ${theme_colors.bg_main};
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    ${tablet({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "120px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
export default function HomeHero() {

    return (
        <Container>
            <HeroWrap>
                <Hero 
                    pageImage={{
                        img1: HeroImg,
                        img2: HeroImg2
                    }}
                    title={{
                        text: "Management consulting & financial planners",
                        fontSize: 30,
                    }}
                    desc={{
                        visible: true,
                        text: "We drive long term value through customer centric strategies and deep business insights",
                        fontSize: 20
                    }}
                    cta={{
                        visible: true,
                        text: "let's chat"
                    }}

                />
            </HeroWrap>
        </Container>
    );
}
