import {theme_colors, homePageSections} from '../../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import Fade from 'react-reveal/Fade';

gsap.registerPlugin(ScrollTrigger);

const Left = styled.div`
    width: 40%;
    margin-left: 30px;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    ${tablet({
        width: "100%",
        marginTop: "30px",
        marginLeft: "0px",
        paddingLeft: "7px",
        paddingRight: "7px",
        alignItems: "center"
    })}

`;
const MTop = styled.div`
    width: 100%;
    margin-top: 130px;
    height: 200px;
    display: none;
    ${tablet({
        display: "block"
    })}
`;
const MGradientBg = styled.div`
    width: 65%;
    height: 200px;
    left: 0px;
    top: 70px;
    position: absolute;
    background-image: linear-gradient(135deg, #009874, #56be89);
    ${mobile({

    })}
`;
const MHeroImageHolder = styled.div`
    width: 85%;
    height: 300px;
    left: 30px;
    top: 120px;
    background-size: cover;
    background-position: center;
    position: absolute;
    ${mobile({

    })}
`;
const Right = styled.div`
    width: 50%;
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
    ${tablet({
        display: "none"
    })}
`;
// const GradientBg = styled.div`
//     width: 25%;
//     height: 200px;
//     right: 0px;
//     top: 70px;
//     position: absolute;
//     background-image: linear-gradient(135deg, #009874, #56be89);
//     ${mobile({

//     })}
// `;
// const HeroImageHolder = styled.div`
//     width: 100%;
//     height: 300px;
//     right: 60px;
//     top: 120px;
//     background-size: cover;
//     position: absolute;
//     ${mobile({

//     })}
// `;
const MainImgWrap = styled.div`
    width: calc(80% - 30px);
    margin-left: 30px;
    margin-top: 70px;
    ${mobile({

    })}
`;
const MainImg = styled.img`
    width: 100%;
`;

const Title = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 45px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 48px;
    ${tablet({
        fontSize: "42px",
        lineHeight: "45px"
    })}
    ${mobile({
        fontSize: "35px",
        lineHeight: "38px",
        textAlign: "center"
    })}
`;
const DescWrap = styled.div`
    margin-top: 15px;
`;
const Desc = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 30px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        textAlign: "center",
        fontSize: "18px",
        lineHeight: "23px",
        fontWeight: "400",
        marginBottom: "60px"
    })}
`;
const BtnMain = styled.div`
    width: max-content;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme_colors.primary_color};
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: ${theme_colors.secondary_color};
    }
    ${mobile({
        width: "100%",
        height: '60px',
        padding: "10px 40px"
    })}
`;
const BtnText = styled.div`
    color: ${theme_colors.bg_main};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: capitalize;
    /* ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        textAlign: "center"
    })} */
`;

export default function Hero({pageImage, title, desc, cta}) {
    const titleRef = useRef(null);
    const PhoneImageRef = useRef(null);

    useEffect(() => {
        gsap.to(titleRef.current, {
            ease:  "power4.inOut",
            delay: 1,
            'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
            opacity: 1,
            y: '0%',
            duration: 1
        })
        gsap.to(PhoneImageRef.current, {
            ease:  "power4.inOut",
            delay: 1.5,
            opacity: 1,
            x: '0%',
            duration: 1
        })
    }, []);

    return (
        <>
            <MTop>
                <MGradientBg />
                <MHeroImageHolder style={{ backgroundImage: `url(${pageImage.img1})`}}/>
            </MTop>
            <Left>
                    <Title>
                        {title.text}
                    </Title>
                {
                    desc.visible == true && 
                    <DescWrap>
                        <Desc>
                            {desc.text}
                        </Desc>
                    </DescWrap>
                }
                {
                    cta.visible == true && 
                    
                            <BtnMain>
                                <Link to={`/contact`} style={{ textDecoration: 'none', width: 'max-content' }}>
                                    <BtnText>Let's talk</BtnText>
                                </Link>
                            </BtnMain>
                        
                }
            </Left>
            <Right>
                {/* <GradientBg /> */}
                {/* <HeroImageHolder style={{ backgroundImage: `url(${pageImage})`}}/> */}
                <MainImgWrap>
                    <MainImg src={pageImage.img2} />
                </MainImgWrap>
            </Right>
        </>
    );
}
