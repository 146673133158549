import "../../assets/css/menuIconStyle.css";
import styled, { keyframes } from 'styled-components';
import {theme_colors} from '../../data';
import { mobile, tablet } from '../../responsive';
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {  MenuOutlined, ClearOutlined, ShoppingBasketOutlined } from "@material-ui/icons";
import axios from 'axios';

import MainLogoImg from '../../assets/images/logo-main.png';
import LogoWhiteImg from '../../assets/images/logo-alt.png';

import Bounce from 'react-reveal/Bounce';

const Container = styled.div`
    width: 100%;
    position: fixed;
    z-index: 10;
`;
const TopBG = styled.div`
    width: 100%;
    height: 70px;
    position: fixed;
    background-color: ${theme_colors.bg_main};
    ${mobile({
        height: "90px",
    })}
`;
const MenuBar = styled.div`
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 3;
    display: none;
    ${mobile({
        display: "block",
    })}
`;
const MainLogoWrap = styled.div`
    position: absolute;
    top: 20px;
    left: 30px;
    ${mobile({
        top: "30px",
    })}
`;
const MainLogo = styled.img`
    width: 110px;
    ${mobile({
        width: "100px",
    })}
`;
const DropDown = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${theme_colors.bg_alt};
    position: fixed;
    transition: all 0.3s ease-in-out;
    transform: ${props=>props.dragging === true ? "translateY(0px)" : "translateY(-160vh)"};
    display: flex;
    flex-direction: column;
    display: none;
    z-index: 2;
    ${mobile({
        display: "block",
        alignItems: "center",
        overflowY: "scroll",
        paddingBottom: "50px"
    })}
`;
const LogoWrap = styled.div`
    position: absolute;
    top: 30px;
    left: 30px;
    ${mobile({

    })}
`;
const Logo = styled.img`
    width: 110px;
    ${mobile({

    })}
`;
const Wrapper = styled.div`
    margin: 13% 60px 30px 60px;
    display: flex;
    justify-content: space-between;
    ${mobile({
        flexDirection: "column",
        alignItems: "center",
        margin: "60px 0px 0px 0px",
        paddingLeft: "15px",
        paddingRight: "15px",
        width: "100%"
    })}
`;
const Left = styled.div`
  ${mobile({
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "50px"
    })}
`;
const MNavWrap = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
`;
const MNavItem = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 35px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
`;
const SocialHandles = styled.div`
    margin-top: 30px;
`;
const HandleItem = styled.div`
    color: ${theme_colors.primary_color};   
    font-size: 22px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
`;
const Contact = styled.div`
    margin-top: 70px;
    ${mobile({
        marginTop: "40px"
    })}
`;
const ContactHead = styled.div`
    color: ${theme_colors.primary_color};  
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
    ${mobile({
        textAlign: "center",
        fontSize: "18px"
    })}
`;
const Phone = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    ${mobile({
        textAlign: "center",
        fontSize: "22px"
    })}
`;
const Email = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 22px;
    font-weight: 600;
    ${mobile({
        textAlign: "center",
        fontSize: "22px"
    })}
`;

const DesktopNav = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    top: 28px;
    right: 0px;
    z-index: 3;
    //border: 1px solid #fff;
    ${mobile({
      display: "none",
    })}
`;
const DNavWrap = styled.div`
    display: flex;
`;
const DNavItem = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 600;
    margin-right: 30px;
    transition: all 0.3s ease;
    &:hover {
        color: ${theme_colors.primary_color};
    }
`;




export default function MainNav() {

    useEffect(() => {
        document.title = "Infuse Digitals";
        //window.scrollTo(0, 0)
    }, []);


    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const toggleMenuBtn = () => {
        setIsMenuOpen(!isMenuOpen);
        const button = document.getElementById("menu-button").classList,
            isopened = "is-opened";
            let isOpen = button.contains(isopened);
            if(isOpen) {
            button.remove(isopened);
            }
            else {
            button.add(isopened);
            }
      }; 

    return (
        <Container>
            <TopBG />
            <Bounce right>
                <MenuBar onClick={toggleMenuBtn}>
                    <button class="button is-text" id="menu-button" onclick="buttonToggle()">
                        <div class="button-inner-wrapper">
                            <span class="icon menu-icon"></span>
                        </div>
                    </button>
                </MenuBar>
            </Bounce>
            <Link to={`/`} style={{ textDecoration: 'none' }}>
                <MainLogoWrap>
                    <Bounce left>
                        <MainLogo src={MainLogoImg} />
                    </Bounce>
                </MainLogoWrap>
            </Link>
            <Bounce right>
                <DropDown dragging={isMenuOpen}>
                        <LogoWrap>
                            <Logo src={LogoWhiteImg} />
                        </LogoWrap>
                    <Wrapper>
                        <Left>
                            <MNavWrap>
                                <Link to={`/`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                                    <MNavItem>Home</MNavItem>
                                </Link>
                                <Link to={`/Services`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                                    <MNavItem>Services</MNavItem>
                                </Link>
                                <Link to={`/about`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                                    <MNavItem>About</MNavItem>
                                </Link>
                                <Link to={`/contact`} style={{ textDecoration: 'none' }} onClick={toggleMenuBtn}>
                                    <MNavItem>Contact</MNavItem>
                                </Link>
                            </MNavWrap>
                            <SocialHandles>
                                <a href="https://www.instagram.com/kudigroup/" style={{ textDecoration: 'none' }} target="_blank">
                                    <HandleItem>Instagram</HandleItem>
                                </a>
                                <a href="https://www.linkedin.com/company/kudigroup/" style={{ textDecoration: 'none' }} target="_blank">
                                    <HandleItem>LinkedIn</HandleItem>
                                </a>
                                <a href="https://twitter.com/kudigroup" style={{ textDecoration: 'none' }} target="_blank">
                                    <HandleItem>Twitter</HandleItem>
                                </a>
                                <a href="https://t.me/kudigroup" style={{ textDecoration: 'none' }} target="_blank">
                                    <HandleItem>Telegram</HandleItem>
                                </a>
                                <a href="https://www.facebook.com/kudigroup1" style={{ textDecoration: 'none' }} target="_blank">
                                    <HandleItem>Facebook</HandleItem>
                                </a>
                            </SocialHandles>
                            <Contact>
                                <ContactHead>Contact Us</ContactHead>
                                <a href="tel:+1 614 733 9671"><Phone>614 733 9671</Phone></a>
                                <a href="mailto:info@kudigroup.com"><Email>info@kudigroup.com</Email></a>
                            </Contact>
                        </Left>
                    </Wrapper>
                </DropDown>
            </Bounce>
            <DesktopNav>
                <Bounce right>
                    <DNavWrap>
                        <Link to={`/`} style={{ textDecoration: 'none' }}>
                            <DNavItem>Home</DNavItem>
                        </Link>
                        <Link to={`/Services`} style={{ textDecoration: 'none' }}>
                            <DNavItem>Services</DNavItem>
                        </Link>
                        <Link to={`/about`} style={{ textDecoration: 'none' }}>
                            <DNavItem>About</DNavItem>
                        </Link>
                        <Link to={`/contact`} style={{ textDecoration: 'none' }}>
                            <DNavItem>Contact</DNavItem>
                        </Link>
                    </DNavWrap>
                </Bounce>
            </DesktopNav>

        </Container>
    );
}
