import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from '../responsive';
import { Link } from "react-router-dom";
import {theme_colors} from '../data';


const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    /* padding-top: 70px;
    padding-bottom: 70px; */
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    width: 90%;
    margin-top: 130px;
    margin-bottom: 50px;
    ${mobile({
        width: "100%",
    })}
`;
const Head = styled.div`
    width: 100%;
`;
const Title = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 45px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 48px;
    margin-bottom: 10px;
    ${tablet({
        fontSize: "42px",
        lineHeight: "45px"
    })}
    ${mobile({
        fontSize: "27px",
        lineHeight: "38px"
    })}
`;
const Desc = styled.div`
    width: 70%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        width: "100%",
        fontSize: "18px",
        lineHeight: "23px",
        fontWeight: "400",
        marginBottom: "60px"
    })}
`;
const Content = styled.div`
    width: 100%;
    margin-top: 50px;
`;
const Block = styled.div`
    width: 100%;
    margin-bottom: 50px;
`;
const BlockHead = styled.div`
    color: ${theme_colors.secondary_color};
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-transform: capitalize;
    ${tablet({

    })}
    ${mobile({
        fontSize: "20px"
    })}
`;
const Body = styled.div`
    width: 70%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 30px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        width: "100%",
        fontSize: "18px",
        lineHeight: "23px",
        fontWeight: "400",
        marginBottom: "60px"
    })}
`;



export default function Terms() {

    useEffect(() => {
        document.title = "Terms & Conditions | Kudi Group - Management Consulting and Financial Planners";
        window.scrollTo(0, 0)
    }, []);

  return (
    <Container>
        <Wrapper>
                <Head>
                    <Title>Terms and conditions</Title>
                    <Desc>
                        Kudi Group and its affiliates (“Kudi Group” or “we”) provides its content on its websites or applications that post a link to this Terms of Use (the “Site”) subject to the following terms and conditions (the “Terms”). We may periodically change the Terms without prior notice, so please check back from time to time. These Terms were last updated on February 6, 2023. By accessing and using this Site, you agree to these Terms. For an explanation of Kudi Group’s practices and policies related to the collection, use, and storage of our users’ information, please read our <Link to={`/privacy`} style={{ textDecoration: 'none' }}>Privacy Policy</Link>.
                    </Desc>
                </Head>
            <Content>
                    <Block>
                        <BlockHead>1. Copyrights</BlockHead>
                        <Body>
                            All content and functionality on the Site, including text, graphics, logos, icons, images, and videos and the selection and arrangement thereof, in addition to any concepts, know-how, tools, frameworks, software, applications or other technology, algorithms, models, processes, and industry perspectives underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof (the “Site Content”) is the exclusive property of Kudi Group or its licensors and, to the extent applicable, is protected by U.S. and international copyright laws. Neither the Site Content nor functionality of the Site, may be copied, reproduced, modified, reverse engineered, altered (including the removal or disabling of any security or technological safeguards, disclaimers, or legends) uploaded, published, uploaded, posted, transmitted, or distributed in any way without our written permission, except for those uses specified in Section 3 – Use of site content. All rights not expressly granted are reserved.
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>2. Trademarks</BlockHead>
                        <Body>
                            The trademarks, service marks, designs, and logos (collectively, the “Trademarks”) displayed on the Site are the registered and unregistered Trademarks of Kudi Group and its licensors. You agree that, except as expressly permitted by us (e.g., through social media sharing tools provided on the Site) or by our licensors, where applicable, you will not refer to or attribute any information to Kudi Group or its licensors in any public medium (e.g., press release, websites, or public social media) for advertising or promotion purposes, or for the purpose of informing or influencing any third party and that you will not use or reproduce any Trademark of, or imply any endorsement by or relationship with, Kudi Group or its licensors.
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>3. Use of site contents</BlockHead>
                        <Body>
                            Kudi Group hereby grants you a limited, non-exclusive, non-transferable, revocable license for the term hereof to access and download, display, and print one copy of the Site Content on any single computer solely for your internal, business use, provided that you do not modify the Site Content in any way (including creating derivative works thereof), that you retain all copyright and other proprietary notices displayed on the Site Content, and that you otherwise comply with these Terms. You may not otherwise reproduce, modify, reverse engineer, distribute, transmit, post, or disclose the Site Content without Kudi Group’s prior written consent. In addition, you may not “mirror” the Site Content or any portion thereof without Kudi Group’s express written consent. Nothing on this Site should be construed as granting directly or indirectly, or by implication any license or right to use any Kudi Group intellectual property other than as expressly set forth herein. The license granted in this section terminates automatically and immediately if you do not comply with these Terms.
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>4. User postings</BlockHead>
                        <Body>
                            <p>You acknowledge and agree that Kudi Group shall own and have the unrestricted right to use, publish, and otherwise exploit any and all information that you post or otherwise publish on the Site in postings, forums or message boards, questionnaire, survey responses, and otherwise, and you acknowledge and agree that, by providing us any such submission, you automatically grant, and hereby do grant, to us a worldwide, non-exclusive, transferable, assignable, sublicensable, fully paid-up, royalty-free, perpetual, irrevocable license and right to use, reproduce, publish, distribute, modify and otherwise exploit such submission for any purpose, and in any form or media, not prohibited by applicable law. In addition, you hereby waive any claims against Kudi Group for any alleged or actual infringements of any rights of privacy or publicity, intellectual property rights, moral rights, or rights of attribution in connection with Kudi Group’s use and publication of such submissions.</p>
                            <p>You covenant that you shall not post or otherwise publish on the Site any materials that (a) are threatening, libelous, defamatory, or obscene; (b) would constitute, or that encourage conduct that would constitute, a criminal offense, give rise to civil liability, or otherwise violate law; (c) infringe the intellectual property, privacy, or other rights of any third parties; (d) contain a computer virus or other destructive element; (e) contain advertising; (f) constitute or contain false or misleading statements; or (g) violates these Terms.</p>
                            <p>Kudi Group does not represent or endorse the accuracy of reliability of information posted to the Site by users. In addition, Kudi Group does not and cannot review all information posted to the Site by users and is not responsible for such information. However, Kudi Group reserves the right to refuse to post and the right to remove any information, in whole or in part, for any reason or for no reason.</p>
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>5. Notices of infringement and takedown by Kudigroup</BlockHead>
                        <Body>
                            Kudi Group prohibits the posting of any information that infringes or violates the copyright rights and/or other intellectual property rights (including rights of privacy and publicity) of any person or entity. If you believe that your intellectual property right (or such a right that you are responsible for enforcing) is infringed by any content on the Site, please write to Kudi Group at the address shown below, giving a written statement that contains: (a) identification of the copyrighted work and/or intellectual property right claimed to have been infringed; (b) identification of the allegedly infringing material on the Site that is requested to be removed; (c) your name, address, and daytime telephone number, and an e-mail address if available; (d) a statement that you have a good faith belief that the use of the copyrighted work and/or exercise of the intellectual property right is not authorized by the owner, its agent, or the law; (e) a statement that the information in the notification is accurate, and, under penalty of perjury, that the signatory is authorized to act on behalf of the owner of the right that is allegedly infringed; and (f) the signature of the intellectual property right owner or someone authorized on the owner’s behalf to assert infringement of the right. Kudi Group will remove any posted submission that infringes the copyright or other intellectual property right of any person under U.S. law upon receipt of such a statement (or any statement in conformance with 17 U.S.C. 512(c)(3)). U.S. law provides significant penalties for submitting such a statement falsely. Under appropriate circumstances, persons who repeatedly submit infringing or unlawful material will be prohibited from posting further submissions. Kudi Group’s contact for submission of notices under this Section 5 is: <a href='mailto:info@kudigroup.com'>info@kudigroup.com</a> 
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>6. Disclaimers</BlockHead>
                        <Body>
                            THE CONTENT AND FUNCTIONALITY ON THE SITE IS PROVIDED WITH THE UNDERSTANDING THAT KUDI GROUP IS NOT HEREIN ENGAGED IN RENDERING PROFESSIONAL ADVICE OR SERVICES TO YOU, NO SITE CONTENT IS INTENDED TO SERVE AS OR SHALL BE DEEMED INVESTMENT, LEGAL, TAX, ACCOUNTING OR OTHER REGULATED ADVICE, AND THAT YOU SHALL REMAIN SOLELY RESPONSIBLE FOR YOUR USE OF ALL SITE CONTENT AND ACKNOWLEDGE THAT ANY RELIANCE UPON THE SITE CONTENT SHALL BE ENTIRELY AT YOUR SOLE OPTION AND RISK. ALL CONTENT AND FUNCTIONALITY ON THE SITE IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. KUDI GROUP AND ITS THIRD-PARTY CONTENT PROVIDERS MAKE NO WARRANTIES, EXPRESS OR IMPLIED, AS TO THE OWNERSHIP, ACCURACY, OR ADEQUACY OF THE SITE CONTENT. KUDI GROUP SHALL HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY INFORMATION PUBLISHED ON LINKED WEBSITES, CONTAINED IN ANY USER SUBMISSIONS PUBLISHED ON THE SITE, OR PROVIDED BY THIRD PARTIES. NEITHER KUDI GROUP NOR ITS THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR LOSSES OR FOR LOST REVENUES OR PROFITS, WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY.
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>7. Indemnification</BlockHead>
                        <Body>
                            You hereby indemnify, defend, and hold harmless Kudi Group and all of its predecessors, successors, parents, subsidiaries, affiliates, officers, directors, shareholders, investors, employees, agents, representatives, and attorneys and their respective heirs, successors, and assigns (“Kudi Group Indemnified Parties”) from and against any and all liability, expenses, costs, or other losses (“Losses”) incurred by Kudi Group and/or Kudi Group Indemnified Parties in connection to any claims arising out of your use of the Site and/or any breach by you of these Terms, including the representations, warranties and covenants you made, if any, by agreeing to these Term. Kudi Group reserves the right to assume, at its own expense, the exclusive defense and control of any matter otherwise subject to indemnification by you.
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>8. Third-party websites & Providers</BlockHead>
                        <Body>
                            We may provide links to third-party websites, and some of the content appearing to be on this Site is in fact supplied, supported, or provided directly or indirectly by third parties, for example, in instances of framing of third-party websites or incorporation through framesets of content supplied by third-party servers. Kudi Group has no responsibility for these third-party websites, which are governed by the terms of use and privacy policies, if any, of the applicable third-party content providers.
                        </Body>
                    </Block>
                    <Block>
                        <BlockHead>9. Governing law; jurisdiction</BlockHead>
                        <Body>
                            These Terms are governed by the laws of the State of Ohio without reference to the principles of conflicts of laws thereof.
                        </Body>
                    </Block> 
            </Content>
        </Wrapper>
    </Container>
  )
}
