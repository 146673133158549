import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import HCImg from '../assets/images/happy_client.png';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    background-image: linear-gradient(135deg, #009874, #56be89);
    padding: 60px 30px;
    box-sizing: border-box;
    ${mobile({
        width: '100%',
        flexDirection: "column",
        padding: "50px 30px 30px 30px"
    })}
`;

const Title = styled.div`
    color: #fff;
    font-size: 28px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 5px;
    ${tablet({
        fontSize: "27px",
    })}
    ${mobile({
        fontSize: "24px",
    })}
`;
const Desc = styled.div`
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin-bottom: 30px;
    ${tablet({
        fontSize: "16px",
        lineHeight: "18px"
    })}
    ${mobile({
        fontSize: "16px",
        lineHeight: "18px",
        textAlign: "center"
    })}
`;
const SvBtn = styled.div`
    width: max-content;
    padding: 10px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #fff;
    }
    ${mobile({
        padding: "10px 40px"
    })}
`;
const SvBtnText = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: capitalize;
`;
export default function GetStarted() {

    return (
        <Container>
            <Wrapper>
                <Title>Let's get started</Title>
                <Desc>Get in touch to see how we can help you</Desc>
                <Link to={`/contact`} style={{ textDecoration: 'none' }}>
                    <SvBtn>
                        <SvBtnText>Get started</SvBtnText>
                    </SvBtn>
                </Link>
            </Wrapper>
        </Container>
    );
}
