import styled from 'styled-components';
import {theme_colors} from '../../data';
import { useEffect, useState } from 'react';
import { mobile, tablet } from '../../responsive';
import LogoWhiteImg from '../../assets/images/logo-alt.png';
import { Link, useLocation } from "react-router-dom";
import { Instagram, LinkedIn, Twitter, Telegram, Facebook } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import "../../assets/css/newsletter.css";
import axios from 'axios';

import Fade from 'react-reveal/Fade';

const Container = styled.div`
    width: 100%;
    background-color: ${theme_colors.bg_alt};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 10px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;

const Wrapper = styled.div`
    display: flex;
    width: 80%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    ${mobile({
        width: '100%',
    })}
`;
const Row = styled.div`
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    ${mobile({
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    })}
`;
const Left = styled.div`
  width: ${props=> props.lenght === "large" ? "50%" : "30%"};
  ${mobile({
        width: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px"
  })}
`;
const LogoWrap = styled.div`
  width: 150px;
  margin-bottom: 20px;
`;
const Logo = styled.img`
  width: 100%;
`;
const Desc = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    max-width: 350px;
    ${tablet({
        fontSize: "14px",
    })}
    ${mobile({
        fontSize: "16px",
        textAlign: "center"
    })}
`;
const Right = styled.div`
  width: ${props=> props.lenght === "large" ? "70%" : "50%"};
  display: flex;
  justify-content: flex-end;
  ${mobile({
        width: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px"
  })}
`;
const Col = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  ${mobile({
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px"
  })}
`;
const Head = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
    ${tablet({
        fontSize: "14px",
    })}
    ${mobile({
        fontSize: "16px",
        textAlign: "center",
        marginBottom: "40px"
    })}
`;
const Items = styled.div`
    ${mobile({
        padding: "10px"
    })}
`;
const ItemText = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin-bottom: 10px;
    ${tablet({
        fontSize: "14px",
    })}
    ${mobile({
        fontSize: "22px",
        lineHeight: "27px",
        textAlign: "center",
        marginBottom: "30px"
    })}
`;
const FormWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  ${tablet({

    })}
    ${mobile({
        marginTop: "50px",
        flexDirection: "column",
        alignItems: "center"
    })}
`;
const FormMessage = styled.div`
    margin-top: 5px;
    /* display:  ${props=> props.displayStatus === true ? "block" : "none"}; */
    color:  ${props=> props.status === "error" ? "#f72828" : "#2b2b2b"};
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    ${tablet({
        fontSize: "16px",
    })}
    ${mobile({
        fontSize: "16px",
        textAlign: "center",
        marginBottom: "50px",
    })}
`;
const Signature = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mobile({
        width: '100%',
        justifyContent: "center",
        alignItems: "center"
  })}
`;
const Socials = styled.div`
  display: flex;
  ${mobile({
        marginBottom: "50px"
  })}
`;
const IconWrap = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  ${mobile({
        marginRight: "7px",
        marginLeft: "7px"
  })}
`;
const Copyright = styled.div`

`;
const CopyText = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    ${tablet({
        fontSize: "14px",
    })}
    ${mobile({
        fontSize: "14px",
        textAlign: "center"
    })}
`;

const useStyles = makeStyles({
  socialIcons: {
      color: theme_colors.secondary_color, 
      fontSize: 42, 
      cursor: 'pointer',
      transition: 'all 0.5s ease',
      '&:hover': {
          color: theme_colors.secondary_color
       },
      '@media (min-width: 780px)' : {
          fontSize: 25
      }
  },
});

export default function Footer() {
  const classes = useStyles();

  const [values, setValues] = useState({
    email: "",
  });

  const [inputStatus, setInputStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const [focused, setFocused] = useState({
    email: false,
  });

  const handleFocus = (e) => {
    setFocused({ ...focused, [e.target.name]: true });
    let x = isInputValid();
    if(x == false) {
      setStatusMessage("Please enter a valid email address");
      setInputStatus("error");
    } else {
      setStatusMessage("");
      setInputStatus("");
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    console.log(values);
};

  const isInputValid = () => {
    let valid = false;

    let x = String(values.email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if ( x && values.email !== "" )
      {
        valid = true;
      } 

    return valid;
};

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isInputValid()){
        setInputStatus("sent");
        setStatusMessage("You have subscribed successfully");
        setFocused({
          email: false,
        });
        setValues({
          email: "",
        });
        /// subcribe email
        handlePost();
    }else {
        console.log('not Valid');
        setInputStatus("error");
        setStatusMessage("Please enter a valid email address");
    }    
  };

  const handlePost = async () => {
    try {
      axios.post('https://sheetdb.io/api/v1/4wh18izp92ahm', {
        email: values.email
      })
      .then(function (response) {
        console.log(response);
      })
    } catch (err) {}

  };
  
  return (
    <Container>
      <Wrapper>
        <Row>
          <Left lenght="small">
            <LogoWrap>
              <Logo src={LogoWhiteImg} />
            </LogoWrap>
            <Desc>
              Driving long term value through customer centric strategies 
            </Desc>
          </Left>
          <Right lenght="large">
            <Col>
              <Head>Navigation</Head>
              <Items>
                <a href='https://indeedhi.re/3KS4RSb' target="_blank">
                  <ItemText>Careers</ItemText>
                </a>
                <Link to={`/privacy`} style={{ textDecoration: 'none' }}>
                  <ItemText>Privacy Policy</ItemText>
                </Link>
                <Link to={`/faq`} style={{ textDecoration: 'none' }}>
                  <ItemText>FAQs</ItemText>
                </Link>
                <Link to={`/terms`} style={{ textDecoration: 'none' }}>
                  <ItemText>Terms & Conditions</ItemText>
                </Link>
                <Link to={`/cookies`} style={{ textDecoration: 'none' }}>
                  <ItemText>Cookie Policy</ItemText>
                </Link>
              </Items>
            </Col>
            <Col>
              <Head>Contact</Head>
              <Items>
                <a href="mailto:info@kudigroup.com"><ItemText>info@kudigroup.com</ItemText></a>
                <ItemText>Columbus, OH</ItemText>
                <a href="tel:+1 614 733 9671"><ItemText>614 733 9671</ItemText></a>
              </Items>
            </Col>
          </Right>
        </Row>
        <Row>
          <Left lenght="large">
            <Head>Newsletter</Head>
            <Desc>
              Stay informed with our latest insights and industry updates. Subscribe to our newsletter now
            </Desc>
            <FormWrap>
              <form onSubmit={handleSubmit}>
                <input 
                  type="email" 
                  id="emailInput" 
                  name="email"
                  value={values.email}
                  placeholder='Enter your email address'
                  onBlur={handleFocus}
                  onChange={onChange}
                  focused={focused.email.toString()}
                  required 
                />
                <button id="submitEmailBtn" >Submit</button>
              </form>
            </FormWrap>
            <FormMessage 
              status={inputStatus} 
              className="footerMsg"
            >
                {statusMessage}
            </FormMessage>
          </Left>
          <Right lenght="small">
            <Signature>
              <Socials>
                <IconWrap>
                  <a href='https://www.instagram.com/kudigroup/' target="_blank">
                    <Instagram className={classes.socialIcons} />
                  </a>
                </IconWrap>
                <IconWrap>
                  <a href='https://www.linkedin.com/company/kudigroup/' target="_blank">
                    <LinkedIn className={classes.socialIcons} />
                  </a>
                </IconWrap>
                <IconWrap>
                  <a href='https://twitter.com/kudigroup' target="_blank">
                    <Twitter className={classes.socialIcons} />
                  </a>
                </IconWrap>
                <IconWrap>
                  <a href='https://t.me/kudigroup' target="_blank">
                    <Telegram className={classes.socialIcons} />
                  </a>
                </IconWrap>
                <IconWrap>
                  <a href='https://www.facebook.com/kudigroup1' target="_blank">
                    <Facebook className={classes.socialIcons} />
                  </a>
                </IconWrap>
              </Socials>
              <Copyright>
                <CopyText>Copyright {new Date().getFullYear()}. Kudi group</CopyText>
              </Copyright>
            </Signature>    
          </Right>
        </Row>
      </Wrapper>
    </Container>
  );
}
