import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import IconImg1 from '../assets/images/industry-icons/icon-tech.png';
import IconImg2 from '../assets/images/industry-icons/icon-bank.png';
import IconImg3 from '../assets/images/industry-icons/icon-estate.png';
import IconImg4 from '../assets/images/industry-icons/icon-health.png';
import IconImg5 from '../assets/images/industry-icons/icon-heart.png';
import IconImg6 from '../assets/images/industry-icons/icon-shop.png';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    width: 80%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    ${tablet({
        width: '90%',
    })}
    ${mobile({
        width: '100%',
    })}
`;
const HeadText = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 25px;
    font-weight: 900;
    text-align: center;
    text-transform: capitalize;
    ${tablet({
        fontSize: "27px",
    })}
    ${mobile({
        fontSize: "24px",
    })}
`;
const IndustryWrap = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin-top: 50px;
    flex-wrap: wrap;
`;
const Item = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 40px);
    margin: 0px 20px 20px 20px;
    padding: 20px;
    background-color: #fff;
    ${mobile({
        width: "100%",
        margin: "0px 10px 20px 10px"
    })}
`;
const IconWrap = styled.div`
    width: 60px;
    margin-bottom: 10px;
`;
const Icon = styled.img`
    width: 100%;
`;
const Title = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-transform: capitalize;
    margin-bottom: 10px;
    ${tablet({
        fontSize: "18px",
    })}
    ${mobile({
        fontSize: "18px",
    })}
`;
const Desc = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    ${tablet({
        fontSize: "16px",
    })}
    ${mobile({
        fontSize: "16px",
        lineHeight: "24px"
    })}
`;
export default function Industries() {

    return (
        <Container>
            <Wrapper>
                <Fade bottom>
                    <HeadText>Industries served</HeadText>
                </Fade>
                <Fade bottom>
                <IndustryWrap>
                    <Item>
                        <IconWrap>
                            <Icon src={IconImg4} />
                        </IconWrap>
                        <Title>Health care & life science</Title>
                        <Desc>
                            We partner with healthcare organizations to develop innovative solutions that drive better outcomes, while leveraging our expertise to transform the journey of care.
                        </Desc>
                    </Item>
                    <Item>
                        <IconWrap>
                            <Icon src={IconImg6} />
                        </IconWrap>
                        <Title>Consumer & retail</Title>
                        <Desc>
                            The consumer and retail industry is characterized by constant change and disruptive trends that often compete with each other. We collaborate with our clients to define and create the next big thing, enhance consumer engagement, and bring their ideas to market faster to stay ahead of ever-changing consumer demands.
                        </Desc>
                    </Item>
                    <Item>
                        <IconWrap>
                            <Icon src={IconImg2} />
                        </IconWrap>
                        <Title>Government</Title>
                        <Desc>
                            Government organizations have unique needs and challenges, and our team has extensive experience working with government agencies at all levels. Whether you're looking to improve operations, implement new technologies, or streamline processes, we have the expertise to help.
                        </Desc>
                    </Item>
                    <Item>
                        <IconWrap>
                            <Icon src={IconImg1} />
                        </IconWrap>
                        <Title>Technology</Title>
                        <Desc>
                            Our support in the technology space improves their performance, scalability, and competitiveness through technology enablement and usage. Our expert team guides IT and digital transformation initiatives from strategy to implementation, ensuring that our clients stay ahead of the curve and become market leaders.
                        </Desc>
                    </Item>
                    <Item>
                        <IconWrap>
                            <Icon src={IconImg3} />
                        </IconWrap>
                        <Title>Real estate</Title>
                        <Desc>
                            We collaborate with real estate investors, property owners, and managers to embrace disruptions and accelerate sustainable growth that benefits clients, their communities, and environments. Our deep understanding of the marketplace enables us to empower clients to pioneer innovation and shape the future of the industry.
                        </Desc>
                    </Item>
                    <Item>
                        <IconWrap>
                            <Icon src={IconImg5} />
                        </IconWrap>
                        <Title>Not for profit</Title>
                        <Desc>
                            Non-profit organizations play a critical role in our communities, and our team is dedicated to supporting their mission. We work with non-profit organizations to improve operations, increase efficiency, and drive mission growth, all while staying true to their values and mission.
                        </Desc>
                    </Item>
                </IndustryWrap>
                </Fade>
            </Wrapper>
        </Container>
    );
}
