import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import HCImg from '../assets/images/happy_client2.png';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    ${tablet({
        width: '90%',
    })}
    ${mobile({
        width: '100%',
        flexDirection: "column"
    })}
`;

const Left = styled.div`
    width: 45%;
    ${mobile({
         width: '100%',
    })}
`;
const ImageHolder = styled.img`
    width: 100%;
    ${mobile({

    })}
`;
const Right = styled.div`
    width: calc(55% - 70px);
    margin-left: 70px;

    ${mobile({
         width: '100%',
         marginLeft: "0px",
         marginTop: "30px",
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
    })}
`;
const Title = styled.div`
    width: 80%;
    color: ${theme_colors.secondary_color};
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 30px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        width: "100%",
        textAlign: "center",
        fontSize: "24px",
        lineHeight: "28px",
        fontWeight: "700",
        marginBottom: "10px"
    })}
`;
const DescWrap = styled.div`
    margin-top: 15px;
`;
const Desc = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 40px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        textAlign: "center",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "300",
        marginBottom: "60px"
    })}
`;
const BtnMain = styled.div`
    width: max-content;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme_colors.primary_color};
    transition: all 0.3s ease;
    &:hover {
        background-color: ${theme_colors.secondary_color};
    }
    ${mobile({
        width: "100%",
        height: '60px',
        padding: "10px 40px"
    })}
`;
const BtnText = styled.div`
    color: ${theme_colors.bg_main};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: capitalize;
    /* ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        textAlign: "center"
    })} */
`;
export default function HappyClient() {

    return (
        <Container>
            <Wrapper>
            <Left>
                <Fade bottom>
                    <ImageHolder src={HCImg}/>
                </Fade>
            </Left>
            <Right>
                <Fade bottom>
                    <Title>
                        We are passionate and driven
                    </Title>
                    <DescWrap>
                        <Desc>
                            Our passion for what we do drives us to deliver exceptional results. With over 30 years of combined diverse industry experience, our team is dedicated to delivering solutions that meet the unique needs and challenges of our clients.
                        </Desc>
                    </DescWrap>
                    <BtnMain>
                        <Link to={`/contact`} style={{ textDecoration: 'none'}}>
                            <BtnText>Let's talk</BtnText>
                        </Link>
                    </BtnMain>
                </Fade>
            </Right>

            </Wrapper>
        </Container>
    );
}
