import "../assets/css/mainStyle.css";
import MainNav from '../components/navigation/MainNav';
import { Outlet } from "react-router-dom";
//import HrWhatsAppChat from '../components/HrWhatsAppChat';
import Footer from '../components/navigation/Footer';

export default function MainApp() {


    return (
        <div>
            <MainNav />
            <Outlet/>
            <Footer />
        </div>
    );
}
