import Img01 from './assets/images/services/01-main.jpeg';
import Img02 from './assets/images/services/02-main.jpg';
import Img02Alt from './assets/images/services/02-alt.png';
import Img03 from './assets/images/services/03-main.jpg';
import Img04 from './assets/images/services/04-main.jpg';
import Img04Alt from './assets/images/services/04-alt.png';
import Img05 from './assets/images/services/05-main.jpg';
import Img05Alt from './assets/images/services/05-alt.png';
import Img06 from './assets/images/services/06-main.jpg';
import Img07 from './assets/images/services/07-main.jpg';

import IgVid1 from './assets/instagram_media/v1.mp4';
import IgImg1 from './assets/instagram_media/p1.jpg';
import IgImg2 from './assets/instagram_media/p2.jpg';

export const theme_colors =   {
  primary_color: '#009874',
  primary_alt: '#009874',
  primary_lite_color: '#a0efcb',
  secondary_color: "#2d2d2d",
  secondary_lite_color: "#333333",
  bg_main: "#f2f2f2",
  bg_alt: "#d7f7e6"
};

  ////// Services ///////

  export const servicelist = [
    {
      id: 0,
      name: "Advisory & Business Management",
      subHeader: "Achieve meaningful results by transforming critical issues with our expert solutions.",
      desc: "Enhance performance by leveraging our expertise in delivering impactful solutions that are informed by data analysis and guided by a proven track record of effective management practices.",
      body: "Kudi Group's Advisory & Business Management Service helps drive growth through powerful insights and creative solutions. Our team of experts provides a full range of services, including:",
      outro: "Our focus on turning risk and compliance into opportunities, developing strategies, and enhancing value is the cornerstone of our approach. With deep industry expertise, innovative thinking, and a collaborative approach, Kudi Group can help your organization evolve and achieve great outcomes. Connect with us in turning insights into action to accelerate your growth.",
      img: Img01,
      img2: "",
      list: [
        "Business Intelligence and Market Dynamics",
        "Strategic and Long Range Planning",
        "Marketing Roadmap",
        "Operational Roadmap Development and Agility",
        "Long-term partnerships or Short-term Business Management",
        "Data Analysis and More"
      ]
    },
    {
      id: 1,
      name: "Financial Planning & Analysis - FP&A",
      subHeader: "We go behind the numbers, so you can stay ahead of your competition",
      desc: "Empower your business leaders with financial and operational data for enterprise grade analytics suitable for scenario modeling, long range plans and proformas.",
      body: "Unleashing the power of data-driven financial planning and analysis to transform data into action while empowering your business leaders with scalable, enterprise-grade analytics that deliver actionable insights and support your organization's mission. Services include:",
      outro: "Our FP&A service covers all aspects of financial management, providing you with the tools and expertise to drive growth and maximize value.",
      img: Img02,
      img2: Img02Alt,
      list: [
        "Planning and Budgeting",
        "Forecasting",
        "Financial Support for Long Range Plans (LRPs)",
        "Financial Modeling",
        "Analysis",
        "Reporting",
        "Financial Close Management"
      ]
    },
    {
      id: 2,
      name: "Web solution",
      subHeader: "Expert web solutions and reliable software support services",
      desc: "Expert web solutions and software support services to help your businesses establish a strong online presence. From custom web development and UX design to eCommerce stores and SEO optimization, we provide comprehensive digital solutions to drive business growth.",
      body: "We deliver top-of-the-line, enterprise-grade digital solutions that scale to the needs of your organization. Our experienced team of web designers, developers, and digital marketers work collaboratively with clients to craft custom web solutions that meet their unique business needs. Our comprehensive range of services includes:",
      outro: "Through our partnership, we empower businesses to establish and maintain a strong online presence, driving measurable growth with targeted digital marketing strategies. We also offer reliable software support services to ensure that all your online systems function efficiently, and our dedicated team provides responsive, proactive support to keep your business ahead of potential issues. Partner with us to achieve your digital marketing goals with confidence.",
      img: Img03,
      img2: "",
      list: [
        "Web design and development",
        "UI and UX design",
        "Branding and identity design",
        "E-commerce store development",
        "Web service integrations",
        "Paid advertising",
        "SEO optimization",
        "Sales automation",
        "Analytics and reporting"
      ]
    },
    {
      id: 3,
      name: "Bookkeeping",
      subHeader: "Achieve accurate financial records and peace of mind",
      desc: "Achieve accurate financial records and peace of mind with our efficient, timely and effective professional bookkeeping service.",
      body: "Unleash Your Potential and Focus on Your Passion - Leave Your Bookkeeping to Us. As a small business owner, you are a master of your craft. Managing the finances and navigating the ever-changing tax laws can be overwhelming. It is why our team of expert bookkeepers are here to help. We offer cost-effective and efficient solutions to keep your books in order and provide you with the data insights you need to make informed decisions, maximize profits, minimize your tax burden and drive growth. Trust us to handle the nitty-gritty details while you concentrate on doing what you do best.",
      outro: "Schedule a complimentary consultation today to discuss your current state, and how we can be of service to your business.",
      img: Img04,
      img2: Img04Alt,
      list: ""
    },
    {
      id: 4,
      name: "Project management",
      subHeader: "We understand how critical cross functional alignments are to delivering strategic projects.",
      desc: "Maximize efficiency, track progress with key performance indicators, and reduce time-to-market with our certified Agile Project Managers.",
      body: "Unlock the full potential of your enterprise projects with our expert Project Management Service. We provide clarity and direction, bridging the gap between ideation and successful implementation. Our cross-functional approach and deep experience, having supported over half a billion dollars in capital deployment in a single budget year, sets us apart as a trusted partner in driving meaningful value creation and realizing project goals. Here are the services we provide:",
      outro: "Let us help you navigate complex projects with confidence and ease; and in the event that you need to track project spend and/or ROI, you can count on us.",
      img: Img05,
      img2: Img05Alt,
      list: [
        "Comprehensive project planning and execution, from conception to completion",
        "Development of project goals, timelines, and budgets",
        "Risk management and mitigation strategies",
        "Identification and allocation of resources",
        "Effective communication and collaboration across all stakeholders",
        "Continuous project monitoring and adjustment as needed",
        "Evaluation and reporting of project outcomes",
        "Expert guidance in overcoming project obstacles and challenges",
        "Proactive management of project scope and changes",
        "Incorporation of industry best practices and innovative techniques to optimize project success"
      ]
    },
    {
      id: 5,
      name: "Nonprofit Management",
      subHeader: "Mission and purpose are at the center of who we are",
      desc: "We offer comprehensive and seamless support for your organization's management needs with our end-to-end services for associations, nonprofit, trade organizations and professional societies.",
      body: "We are dedicated to helping nonprofit organizations achieve their goals and make a positive impact on their communities. Our experience demonstrates our commitment to walking alongside your organization every step of the way. With our passion for giving back and years of experience in supporting families and leading nonprofit organizations, we bring a wealth of knowledge and expertise to the table. Some of our service include:",
      outro: "Connect with us for a complimentary discovery call.",
      img: Img06,
      img2: "",
      list: [
        "Strategic Planning: We work with your organization to develop a strategic plan that aligns with your mission and vision. Our team helps you identify key objectives, prioritize initiatives, and create a roadmap to success.",
        "Board Development: We provide guidance on building and managing an effective board of directors. Our consultants can help your organization identify and recruit board members, establish governance policies, and create board orientation and training programs.",
        "Fundraising: We help nonprofit organizations develop and execute successful fundraising strategies. Our team can assist with grant writing, major gift programs, planned giving, and other fundraising initiatives.",
        "Program Evaluation: We conduct program evaluations to help nonprofit organizations assess their impact and identify opportunities for improvement. Our consultants can assist with data collection, analysis, and reporting.",
        "Capacity Building: We provide support to help nonprofit organizations build their internal capacity. Our team can assist with staffing, volunteer management, technology, and other areas that are critical to the success of your organization."
      ]
    },
    {
      id: 6,
      name: "Legal Support",
      subHeader: "Reliable and efficient legal services to mitigate risk and ensure compliance with regulatory requirements",
      desc: "We understand that navigating the legal landscape can be challenging, we are here to help. Our experienced attorneys are committed to supporting our clients in mitigating risk and ensuring compliance with regulatory requirements.",
      body: "Protect your business from avoidable risks and ensure compliance with applicable statutes and regulations with our top-notch legal services. At our firm, we are committed to providing unmatched legal support to our clients. We understand that legal issues can be particularly challenging for early stage businesses, which often lack the resources and expertise necessary to handle complex legal matters. That's where we come in. Our experienced team is dedicated to providing practical and effective legal solutions to help you start and scale your business. Our legal services cover a wide range of areas, including:",
      outro: "We prioritize a comprehensive understanding of each client's needs and objectives and we work closely with them to develop customized legal strategies. We strive to provide efficient and cost-effective legal solutions that meet the specific needs of our clients.",
      img: Img07,
      img2: "",
      list: [
        "Business formation and structure: We help small businesses choose the right business entity, whether it's a sole proprietorship, partnership, LLC, or corporation. We also assist with the drafting of partnership agreements, shareholder agreements, and operating agreements.",
        "Contract drafting and review: We help businesses with the drafting and review of contracts, including employment agreements, partnerships, vendors, and lease agreements. We also provide guidance on contract negotiations and disputes.",
        "Intellectual property / Trademarks: We help small businesses protect their intellectual property, including trademarks, copyrights, and patents. We assist with the filing of trademark and copyright applications, as well as enforcement of intellectual property rights.",
        "Employment law: We provide guidance on compliance with employment laws and regulations, including wage and hour laws, discrimination and harassment, and employee termination.",
        "Litigation: We represent small businesses in a variety of legal disputes, including breach of contract, intellectual property disputes, and employment disputes."
      ]
    },
  ];

  //// IG Feed Demo Data ///

  export const feeds = [
    {
      id: 0,
      type: "video",
      content: "Welcome to Kudi Group. \n\nWe drive long term value through customer centric strategies.\n\n#consulting #business #management #finance #customers #partners",
      media: IgVid1,
      permalink: "https://www.instagram.com/reel/CofhmDRDyCY/"
    },
    {
      id: 1,
      type: "image",
      content: "We are Kudi Group. \n\nWe'd love to partner with you on journey to starting or scaling your business. \n\nConnect with us to get us started\n\n#growth #startup #entrepreneurs",
      media: IgImg1,
      permalink: "https://www.instagram.com/p/CpC-JWXOUQz/"
    },
    {
      id: 2,
      type: "image",
      content: "It's a New Month!!!!!\n\nJoin our Telegram Network. Be First to know when we go LIVE.\n\nHappy New Month 😊\n\nhttps://t.me/kudigroup",
      media: IgImg2,
      permalink: "https://www.instagram.com/p/CpP51TVOL2J/"
    }
  ];