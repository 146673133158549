import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { useSpring, animated } from "react-spring";
import HCImg from '../assets/images/happy_client.png';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    background-image: linear-gradient(135deg, #009874, #56be89);
    padding: 60px 30px;
    box-sizing: border-box;
    ${tablet({
        width: '90%',
    })}
    ${mobile({
        width: '100%',
        flexDirection: "column",
        padding: "50px 30px 30px 30px"
    })}
`;
const Item = styled.div`
    width: calc(25% - 40px);
    display: flex;
    flex-direction: column;
    margin: 0px 20px 0px 20px;
    align-items: center;
    ${mobile({
        width: "100%",
        margin: "0px 0px 40px 0px"
    })}
`;
const Figure = styled.div`
    color: #fff;
    font-size: 35px;
    font-weight: 900;
    text-align: center;
    display: flex;
    ${tablet({
        fontSize: "27px",
    })}
    ${mobile({
        fontSize: "24px",
    })}
`;
const Desc = styled.div`
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    ${tablet({
        fontSize: "16px",
        lineHeight: "18px"
    })}
    ${mobile({
        fontSize: "16px",
        lineHeight: "18px",
        textAlign: "center"
    })}
`;

function Number({ n }) {
    const { number } = useSpring({
        from: { number: 0 },
        number: n,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
}

export default function Stats() {


    return (
        <Container>
            <Wrapper>
                <Item>
                    <Figure>$1.0B+</Figure>
                    <Desc>P&L Managed</Desc>
                </Item>
                <Item>
                    <Figure>$2.0M+</Figure>
                    <Desc>Assets Under Management</Desc>
                </Item>
                <Item>
                    <Figure><Number n={200} />+</Figure>
                    <Desc>Strategic Projects Managed</Desc>
                </Item>
                <Item>
                    <Figure><Number n={7} />+</Figure>
                    <Desc>Industries Served</Desc>
                </Item>
            </Wrapper>
        </Container>
    );
}
