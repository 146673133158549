import {theme_colors, homePageSections} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 130px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "120px",
        paddingLeft: "15px",
        paddingRight: "15px"
    })}
`;
const Wrapper = styled.div`
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    ${tablet({
        width: '90%'
    })}
    ${mobile({
        width: '100%'
    })}
`;
const ValueItem = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 40px);
    box-sizing: border-box;
    margin: 0px 20px 20px 20px;
    padding: 20px;
    ${mobile({
        width: '100%',
        alignItems: "center",
        margin: "0px 0px 20px 0px",
        padding: "20px 10px 20px 10px"
    })}
`;
const Title = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    ${tablet({
        fontSize: "18px",
    })}
    ${mobile({
        fontSize: "18px",
        textAlign: "center"
    })}
`;
const Bar = styled.div`
    width: 150px;
    height: 2px;
    background-color: ${theme_colors.primary_color};
    margin: 10px 0px 10px 0px;
`;
const Desc = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 500;
    ${tablet({
        fontSize: "16px",
    })}
    ${mobile({
        fontSize: "16px",
        textAlign: "center"
    })}
`;

export default function HomeHero() {

    return (
        <Container>
            <Wrapper>
                <ValueItem>
                    <Fade bottom>
                        <Title>Trust</Title>
                        <Bar />
                        <Desc>
                            We earn trust by delivering transparent, honest and integrous services to build strong client relations. We understand business complexities and partner with clients to provide meaningful solutions. This approach has earned us our clients' trust.
                        </Desc>
                    </Fade>
                </ValueItem>
                <ValueItem>
                    <Fade bottom>
                        <Title>Equity</Title>
                        <Bar />
                        <Desc>
                            Our commitment to growth is unwavering. We promote equity and provide inclusive services with a diverse team of experts. As partners, we support growth and make tough decisions for sustained positive value.
                        </Desc>
                    </Fade>
                </ValueItem>
                <ValueItem>
                    <Fade bottom>
                        <Title>Experience</Title>
                        <Bar />
                        <Desc>
                            With over 30 years of diverse industry experience, our team brings a wealth of knowledge and expertise to each project. We leverage our experience to provide customized solutions that meet the unique needs and challenges of our clients.
                        </Desc>
                    </Fade>
                </ValueItem>
                <ValueItem>
                    <Fade bottom>
                        <Title>Excellence</Title>
                        <Bar />
                        <Desc>
                            Our unwavering commitment to excellence sets us apart. We deliver exceptional service and results for our clients through continuous improvement. Our professionals are passionate and bring a focus to every aspect of your business. We are dedicated partners, driving your success with thoughtful ideas and collaboration.
                        </Desc>
                    </Fade>
                </ValueItem>
            </Wrapper>

        </Container>
    );
}
