import {theme_colors, homePageSections, servicelist} from '../data';
import styled, { keyframes } from 'styled-components';
import { mobile, tablet } from '../responsive';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import LogoBlack from '../assets/images/logo-alt.png';

import HCImg from '../assets/images/service2.jpg';
import { Close } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';



const Container = styled.div`
    width: 100%;
   // height: 100vh;
    background-color: ${theme_colors.bg_main};
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    ${mobile({
        flexDirection: "column",
        height: "max-content",
        justifyContent: "center",
        paddingTop: "40px",
        paddingLeft: "0px",
        paddingRight: "0px",
        position: 'relative'
    })}
`;
const Wrapper = styled.div`
    width: 70%;
    ${tablet({
        width: "90%"
    })}
    ${mobile({
        width: '100%',
        flexDirection: "column",
        paddingLeft: "15px",
        paddingRight: "15px",
    })}
`;
const HeadText = styled.div`
    color: ${theme_colors.primary_color};
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    ${tablet({
        fontSize: "27px",
    })}
    ${mobile({
        fontSize: "24px",
    })}
`;
const Services = styled.div`
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid yellow; */
    /* ${mobile({
        width: '100%',
        flexDirection: "column"
    })}
    ${mobile({
         width: '100%',
    })} */
`;
const Service = styled.div`
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid #000; */
    ${mobile({
        width: '100%',
        flexDirection: "column"
    })}
    ${mobile({
         width: '100%',
    })}
`;
const Left = styled.div`
    width: 35%;
    ${mobile({
         width: '100%',
    })}
`;
const ImageHolder = styled.img`
    width: 100%;
    ${mobile({

    })}
`;
const Right = styled.div`
    width: calc(65% - 40px);
    margin-left: ${props=>props.leftM && "40px"};
    margin-right: ${props=>!props.leftM && "40px"};

    ${mobile({
         width: '100%',
         marginLeft: "0px",
         marginTop: "30px",
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
    })}
`;
const Title = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 23px;
    font-weight: 600;
    line-height: 26px;
    text-transform: capitalize;
    ${tablet({

    })}
    ${mobile({
        fontSize: "23px",
        textAlign: "center"
    })}
`;
const DescWrap = styled.div`
    margin-top: 15px;
`;
const Desc = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 30px;
    ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        textAlign: "center",
        fontSize: "18px",
        lineHeight: "23px",
        fontWeight: "400",
        marginBottom: "60px"
    })}
`;
const BtnMain = styled.div`
    width: max-content;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme_colors.primary_color};
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: ${theme_colors.secondary_color};
    }
    ${mobile({
        padding: "10px 40px"
    })}
`;
const BtnText = styled.div`
    color: ${theme_colors.bg_main};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: capitalize;
    /* ${tablet({
        fontSize: "20px",
        lineHeight: "25px"
    })}
    ${mobile({
        textAlign: "center"
    })} */
`;
const ServiceView = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #e6fcf5;
    padding: 20px 20px 50px 20px;
    transition: all 0.3s ease-in-out;
    top: 0;
    transform: ${props=>props.dragging === true ? "translateY(0px)" : "translateY(-110vh)"};
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
`;
const MainLogoWrap = styled.div`
    position: absolute;
    top: 20px;
    left: 30px;
    ${mobile({
        top: "30px",
    })}
`;
const MainLogo = styled.img`
    width: 110px;
    ${mobile({
        width: "100px",
    })}
`;
const CloseBtn = styled.div`
    top: 30px;
    right: 30px;
    position: absolute;
    cursor: pointer;
`;
const SvWrap = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    ${tablet({
        width: "90%"
    })}
    ${mobile({
        width: "100%",
    })}
`;
const Hero = styled.div`
    width: 100%;
    height: 500px;
`;
const ImageWrap = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center; 
    /* object-fit: cover; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const TextWrap = styled.div`
    width: 50%;
    margin-right: 30px;
    height: max-content;
    display: flex;
    flex-direction: column;
    ${tablet({
        width: "70%"
    })}
`;
const SvHead = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 36px;
    font-weight: 900;
    line-height: 40px;
    text-transform: capitalize;
    background-color: #2d2d2d90;
    background-color: #00987490;
    background-color: #ffffff90;
    padding: 20px;
    ${tablet({

    })}
    ${mobile({
        display: "none"
    })}
`;
const SvSubHead = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    text-transform: capitalize;
    background-color: #ffffff90;
    background-color: #a0efcb90;
    padding: 20px;
    ${tablet({

    })}
    ${mobile({
        display: "none"
    })}
`;
const SvHeadMob = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 23px;
    font-weight: 900;
    line-height: 32px;
    margin-top: 10px;
    display: none;
    ${tablet({

    })}
    ${mobile({
        display: "block",  
    })}
`;
const SvSubHeadMob = styled.div`
    color: ${theme_colors.secondary_color};
    font-size: 23px;
    font-weight: 300;
    line-height: 30px;
    text-transform: capitalize;
    display: none;
    ${tablet({

    })}
    ${mobile({
        display: "block"

    })}
`;
const SvBody = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
    ${tablet({

    })}
    ${mobile({
    })}
`;
const SvOutro = styled.div`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 10px;
    ${tablet({

    })}
    ${mobile({

    })}
`;
const Listing = styled.div`
    margin-bottom: 10px;
`;
const List = styled.ul`

`;
const ListItem = styled.li`
    width: 100%;
    color: ${theme_colors.secondary_color};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 5px;
    ${tablet({
    })}
    ${mobile({

    })}
`;
const AltImgWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`;
const AltImage = styled.img`
    width: 40%;
    ${mobile({
        width: "100%"
    })}
`;
const BtnWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const SvBtn = styled.div`
    width: max-content;
    padding: 10px 30px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme_colors.primary_color};
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: ${theme_colors.secondary_color};
    }
    ${mobile({
        padding: "10px 40px"
    })}
`;
const SvBtnText = styled.div`
    color: ${theme_colors.bg_main};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: capitalize;
`;
const useStyles = makeStyles({
    closeIcon: {
        color: theme_colors.secondary_color, 
        fontSize: 52, 
        cursor: 'pointer',
        transition: 'all 0.5s ease',
        '&:hover': {
            color: theme_colors.secondary_color
         },
        '@media (min-width: 780px)' : {
            fontSize: 32
        }
    },
  });
export default function OurServices() {
    const classes = useStyles();
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [isServiceOpen, setIsServiceOpen] = useState(false);
    const [singleService, setSingleService] = useState({
        id: servicelist[0].id,
        name: servicelist[0].name,
        subHeader: servicelist[0].subHeader,
        desc: servicelist[0].desc,
        body: servicelist[0].body,
        outro: servicelist[0].outro,
        img: servicelist[0].img,
        img2: servicelist[0].img2,
        list: servicelist[0].list,
    });

    const toggleServiceView = (x) => {
        setIsServiceOpen(x);
      };

    const openServiceView = (id) => {
        setSingleService(servicelist[id]);
        toggleServiceView(true);
    }; 


    return (
        <Container>
            <Wrapper>
                {/* <HeadText>Our services</HeadText> */}
                <Services>
                    {
                        windowSize.current[0] > 760 ? servicelist.map((service, index) => {
                                                            return (index + 1 ) % 2 === 0 ? <Service>
                                                                                                <Right leftM={false}>
                                                                                                    <Title>
                                                                                                    {service.name}
                                                                                                    </Title>
                                                                                                    <DescWrap>
                                                                                                        <Desc>
                                                                                                            {service.desc.length > 270 ? 
                                                                                                                `${service.desc.substring(0, 270)}...` : service.desc
                                                                                                            }
                                                                                                        </Desc>
                                                                                                    </DescWrap>
                                                                                                    <BtnMain onClick={()=>openServiceView(service.id)}>
                                                                                                        <BtnText>Read more</BtnText>
                                                                                                    </BtnMain>
                                                                                                </Right>
                                                                                                <Left leftM={false}>
                                                                                                    <ImageHolder src={service.img}/>
                                                                                                </Left>
                                                                                            </Service>
                                                                                        :   <Service>
                                                                                                <Left leftM={false}>
                                                                                                    <ImageHolder src={service.img}/>
                                                                                                </Left>
                                                                                                <Right leftM={true}>
                                                                                                    <Title>
                                                                                                    {service.name}
                                                                                                    </Title>
                                                                                                    <DescWrap>
                                                                                                        <Desc>
                                                                                                            {service.desc.length > 270 ? 
                                                                                                                `${service.desc.substring(0, 270)}...` : service.desc
                                                                                                            }
                                                                                                        </Desc>
                                                                                                    </DescWrap>
                                                                                                    <BtnMain onClick={()=>openServiceView(service.id)}>
                                                                                                        <BtnText>Read more</BtnText>
                                                                                                    </BtnMain>
                                                                                                </Right>
                                                                                            </Service>
                                                            })
                                                    : servicelist.map((service, index) => {
                                                        return <Service>
                                                                    <Left leftM={false}>
                                                                        <ImageHolder src={service.img}/>
                                                                    </Left>
                                                                    <Right leftM={true}>
                                                                        <Title>
                                                                            {service.name}
                                                                        </Title>
                                                                        <DescWrap>
                                                                            <Desc>
                                                                                {service.desc.length > 270 ? 
                                                                                    `${service.desc.substring(0, 270)}...` : service.desc
                                                                                }
                                                                            </Desc>
                                                                        </DescWrap>
                                                                        <BtnMain onClick={()=>openServiceView(service.id)}>
                                                                            <BtnText>Read more</BtnText>
                                                                        </BtnMain>
                                                                    </Right>
                                                                </Service>
                                                        })
                    
                    }
                </Services>
            </Wrapper>

            <ServiceView dragging={isServiceOpen}>
                <MainLogoWrap>
                    <MainLogo src={LogoBlack} />
                </MainLogoWrap>
                <CloseBtn onClick={() => toggleServiceView(false)}>
                    <Close className={classes.closeIcon} />
                </CloseBtn>
                <SvWrap>
                    <Hero>
                        <ImageWrap style={{ backgroundImage: `url(${singleService.img})`}}>
                            <TextWrap>
                                <SvHead>{singleService.name}</SvHead>
                                <SvSubHead>{singleService.subHeader}</SvSubHead>
                            </TextWrap>
                        </ImageWrap>
                    </Hero>
                    <SvHeadMob>{singleService.name}</SvHeadMob>
                    <SvSubHeadMob>{singleService.subHeader}</SvSubHeadMob>
                    <SvBody>
                        {singleService.body}
                    </SvBody>
                    {
                        singleService.list != "" &&  <Listing>
                                                        <List>
                                                            {
                                                                singleService.list.map((item) => {
                                                                    return <ListItem>{item}</ListItem>
                                                                })
                                                            }
                                                        </List>
                                                    </Listing>
                    }
                    {
                        singleService.img2 != "" && <AltImgWrap><AltImage src={singleService.img2} /></AltImgWrap>
                    }
                    <SvOutro>
                        {singleService.outro}
                    </SvOutro>
                    <BtnWrap>
                        <SvBtn>
                        <Link to={`/contact`} style={{ textDecoration: 'none'}}>
                            <SvBtnText>Get started</SvBtnText>
                        </Link>
                        </SvBtn>
                    </BtnWrap>
                </SvWrap>
            </ServiceView>
        </Container>
    );
}
